import Layout from "@/layout";

const account_back_all_router_config = [
  {
    path: "/account-manage",
    component: Layout,
    hidden: true,
    children: [
      // {
      //   path: "/account-manage/back-all/list",
      //   component: () => import("@/views/account-back-all/list/index.vue"),
      //   hidden: true,
      //   meta: { title: "油站后结算总账户", icon: "list" },
      // },
      {
        path: "/account-manage/back-all/center",
        component: () => import("@/views/account-back-all/center/index.vue"),
        hidden: true,
        meta: { title: "账户中心", icon: "list" },
      },
      {
        path: "/account-manage/back-all/review",
        component: () => import("@/views/account-back-all/review/index.vue"),
        hidden: true,
        meta: { title: "提现审核", icon: "list" },
      },
      {
        path: "/account-manage/back-all/cash",
        component: () => import("@/views/account-back-all/cash/index.vue"),
        hidden: true,
        meta: { title: "审核提现", icon: "list" },
      },
      {
        path: "/account-manage/back-all/mark",
        component: () => import("@/views/account-back-all/mark/index.vue"),
        hidden: true,
        meta: { title: "标记提现", icon: "list" },
      },
      {
        path: "/account-manage/back-all/detail",
        component: () => import("@/views/account-back-all/detail/index.vue"),
        hidden: true,
        meta: { title: "资金明细", icon: "list" },
      },
      {
        path: "/account-manage/back-all/download",
        component: () => import("@/views/account-back-all/download/index.vue"),
        hidden: true,
        meta: { title: "对账下载", icon: "list" },
      },
      {
        path: "/account-manage/back-all/look",
        component: () => import("@/views/account-back-all/detail/index.vue"),
        hidden: true,
        meta: { title: "查看明细", icon: "list" },
      },
      {
        path: "/account-manage/back-all/record",
        component: () => import("@/views/account-back-all/record/index.vue"),
        hidden: true,
        meta: { title: "提现记录-操作记录", icon: "list" },
      },
    ],
  },
  {
    path: "/account-manage",
    component: Layout,
    hidden: true,
    children: [
      // {
      //   path: "/account-manage/front-all/list",
      //   component: () => import("@/views/account-front-all/list/index.vue"),
      //   hidden: true,
      //   meta: { title: "油站充值总账户", icon: "list" },
      // },
      {
        path: "/account-manage/front-all/center",
        component: () => import("@/views/account-front-all/center/index.vue"),
        hidden: true,
        meta: { title: "账户中心", icon: "list" },
      },
      {
        path: "/account-manage/front-all/detail",
        component: () => import("@/views/account-front-all/detail/index.vue"),
        hidden: true,
        meta: { title: "资金明细", icon: "list" },
      },
      {
        path: "/account-manage/front-all/pending",
        component: () => import("@/views/account-front-all/pending/index.vue"),
        hidden: true,
        meta: { title: "消返待处理列表", icon: "list" },
      },
      {
        path: "/account-manage/front-all/regression",
        component: () =>
          import("@/views/account-front-all/regression/index.vue"),
        hidden: true,
        meta: { title: "消返", icon: "list" },
      },
      {
        path: "/account-manage/front-all/download",
        component: () => import("@/views/account-front-all/download/index.vue"),
        hidden: true,
        meta: { title: "对账下载", icon: "list" },
      },
      {
        path: "/account-manage/front-all/look",
        component: () => import("@/views/account-front-all/detail/index.vue"),
        hidden: true,
        meta: { title: "查看明细", icon: "list" },
      },
      {
        path: "/account-manage/front-all/recharge",
        component: () => import("@/views/account-front-all/recharge/index.vue"),
        hidden: true,
        meta: { title: "充值", icon: "list" },
      },
      {
        path: "/account-manage/front-all/records",
        component: () => import("@/views/account-front-all/records/index.vue"),
        hidden: true,
        meta: { title: "充值记录", icon: "list" },
      },
      {
        path: "/account-manage/front-all/return",
        component: () => import("@/views/account-front-all/return/index.vue"),
        hidden: true,
        meta: { title: "总账户充值回收", icon: "list" },
      },
    ],
  },
  {
    path: "/account-manage",
    component: Layout,
    hidden: true,
    children: [
      // {
      //   path: "/account-manage/pay-set/list",
      //   component: () =>
      //     import("@/views/account-manage/pay-set/list/index.vue"),
      //   hidden: true,
      //   meta: { title: "支付平台设置", icon: "list" },
      // },
      {
        path: "/account-manage/pay-set/records",
        component: () =>
          import("@/views/account-manage/pay-set/records/index.vue"),
        hidden: true,
        meta: { title: "支付平台设置变更记录", icon: "list" },
      },
    ],
  },
  // 渠道结算账户
  {
    path: "/account-manage",
    component: Layout,
    hidden: true,
    children: [
      // {
      //   path: "/account-manage/channel-js/list",
      //   component: () =>
      //     import("@/views/account-manage/channel-js/list/index.vue"),
      //   hidden: true,
      //   meta: { title: "账户列表", icon: "list" },
      // },
      {
        path: "/account-manage/channel-js/detail",
        component: () =>
          import("@/views/account-manage/channel-js/detail/index.vue"),
        hidden: true,
        meta: { title: "资金明细", icon: "list" },
      },
      {
        path: "/account-manage/channel-js/money",
        component: () =>
          import("@/views/account-manage/channel-js/money/index.vue"),
        hidden: true,
        meta: { title: "提现", icon: "list" },
      },
      {
        path: "/account-manage/channel-js/records",
        component: () =>
          import("@/views/account-manage/channel-js/records/index.vue"),
        hidden: true,
        meta: { title: "提现记录", icon: "list" },
      },
      {
        path: "/account-manage/channel-js/mark",
        component: () =>
          import("@/views/account-manage/channel-js/mark/index.vue"),
        hidden: true,
        meta: { title: "标记提现", icon: "list" },
      },
    ],
  },
  // 渠道垫资账户
  {
    path: "/account-manage",
    component: Layout,
    hidden: true,
    children: [
      // {
      //   path: "/account-manage/channel-dz/list",
      //   component: () =>
      //     import("@/views/account-manage/channel-dz/list/index.vue"),
      //   hidden: true,
      //   meta: { title: "渠道垫资账户列表", icon: "list" },
      // },
      {
        path: "/account-manage/channel-dz/detail",
        component: () =>
          import("@/views/account-manage/channel-dz/detail/index.vue"),
        hidden: true,
        meta: { title: "渠道垫资资金明细", icon: "list" },
      },
      {
        path: "/account-manage/channel-dz/records-dz",
        component: () =>
          import("@/views/account-manage/channel-dz/records-dz/index.vue"),
        hidden: true,
        meta: { title: "垫资记录", icon: "list" },
      },
      {
        path: "/account-manage/channel-dz/money",
        component: () =>
          import("@/views/account-manage/channel-dz/money/index.vue"),
        hidden: true,
        meta: { title: "还款&批量还款", icon: "list" },
      },
      {
        path: "/account-manage/channel-dz/records-hk",
        component: () =>
          import("@/views/account-manage/channel-dz/records-hk/index.vue"),
        hidden: true,
        meta: { title: "还款记录", icon: "list" },
      },
      {
        path: "/account-manage/channel-dz/back",
        component: () =>
          import("@/views/account-manage/channel-dz/back/index.vue"),
        hidden: true,
        meta: { title: "撤销还款", icon: "list" },
      },
    ],
  },
  // 渠道充值账户
  {
    path: "/account-manage",
    component: Layout,
    hidden: true,
    children: [
      {
        path: "/account-manage/channel-cz/list",
        component: () =>
          import("@/views/account-manage/channel-cz/list/index.vue"),
        hidden: true,
        meta: { title: "账户列表", icon: "list" },
      },
      {
        path: "/account-manage/channel-cz/money",
        component: () =>
          import("@/views/account-manage/channel-cz/money/index.vue"),
        hidden: true,
        meta: { title: "渠道充值", icon: "list" },
      },
      {
        path: "/account-manage/channel-cz/money-back",
        component: () =>
          import("@/views/account-manage/channel-cz/money-back/index.vue"),
        hidden: true,
        meta: { title: "渠道充值回收", icon: "list" },
      },
      {
        path: "/account-manage/channel-cz/detail",
        component: () =>
          import("@/views/account-manage/channel-cz/detail/index.vue"),
        hidden: true,
        meta: { title: "资金明细", icon: "list" },
      },
      {
        path: "/account-manage/channel-cz/records-qd",
        component: () =>
          import("@/views/account-manage/channel-cz/records-qd/index.vue"),
        hidden: true,
        meta: { title: "渠道充值记录", icon: "list" },
      },
      {
        path: "/account-manage/channel-cz/records-sj",
        component: () =>
          import("@/views/account-manage/channel-cz/records-sj/index.vue"),
        hidden: true,
        meta: { title: "司机充值记录", icon: "list" },
      },
    ],
  },
  // 油站聚合结算账户
  {
    path: "/account-manage",
    component: Layout,
    hidden: true,
    children: [
      // {
      //   path: "/account-manage/station-account/polymerize/list",
      //   component: () =>
      //     import("@/views/account-manage/polymerize/list/index.vue"),
      //   hidden: true,
      //   meta: { title: "油站聚合结算账户列表", icon: "list" },
      // },
      {
        path: "/account-manage/station-account/polymerize/detail",
        component: () =>
          import("@/views/account-manage/polymerize/detail/index.vue"),
        hidden: true,
        meta: { title: "资金明细", icon: "list" },
      },
      {
        path: "/account-manage/station-account/polymerize/download",
        component: () =>
          import("@/views/account-manage/polymerize/download/index.vue"),
        hidden: true,
        meta: { title: "对账下载", icon: "list" },
      },
    ],
  },
  // 易宝账户
  {
    path: "/account-manage",
    component: Layout,
    hidden: true,
    children: [
      // {
      //   path: "/account-manage/yeepay-account/pay-list",
      //   component: () =>
      //     import("@/views/account-manage/yeepay-account/list/index.vue"),
      //   hidden: true,
      //   meta: { title: "平台商账户列表", icon: "list" },
      // },
      {
        path: "/account-manage/yeepay-account/pay",
        component: () =>
          import("@/views/account-manage/yeepay-account/pay/index.vue"),
        hidden: true,
        meta: { title: "企业付款", icon: "list" },
      },
      {
        path: "/account-manage/yeepay-account/record",
        component: () =>
          import("@/views/account-manage/yeepay-account/record/index.vue"),
        hidden: true,
        meta: { title: "企业付款记录", icon: "list" },
      },
      {
        path: "/account-manage/yeepay-account/pay-jzb",
        component: () =>
          import("@/views/account-manage/yeepay-account/pay-jzb/index.vue"),
        hidden: true,
        meta: { title: "记账簿支付", icon: "list" },
      },
      {
        path: "/account-manage/yeepay-account/record-jzb",
        component: () =>
          import("@/views/account-manage/yeepay-account/record-jzb/index.vue"),
        hidden: true,
        meta: { title: "记账簿支付记录", icon: "list" },
      },
    ],
  },
];
export default account_back_all_router_config;
