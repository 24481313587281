import Layout from "@/layout";

const merchant_config = [
  {
    path: "/merchant-manage",
    component: Layout,
    hidden: true,
    children: [
      // {
      //     path: "/merchant/merchant-list",
      //     component: () => import("@/views/merchant/list/index.vue"),
      //     hidden: true,
      //     meta: { title: "商户列表", icon: "look" },
      // },
      {
        path: "/merchant-manage/merchant-detail",
        component: () => import("@/views/merchant/detail/index.vue"),
        hidden: true,
        meta: { title: "商户详情", icon: "look" },
      },
      {
        path: "/merchant-manage/merchant-edit",
        component: () => import("@/views/merchant/edit/index.vue"),
        hidden: true,
        meta: { title: "商户编辑", icon: "look" },
      },
      {
        path: "/merchant-manage/merchant-contract",
        component: () => import("@/views/merchant/contract/index.vue"),
        hidden: true,
        meta: { title: "合同信息", icon: "look" },
      },
      // {
      //   path: "/merchant-manage/account-list",
      //   component: () => import("@/views/merchant/account/list/index.vue"),
      //   hidden: true,
      //   meta: { title: "对公账户列表", icon: "list" },
      // },
      {
        path: "/merchant-manage/account-detail",
        component: () => import("@/views/merchant/account/detail/index.vue"),
        hidden: true,
        meta: { title: "对公账户详情", icon: "list" },
      },
      {
        path: "/merchant-manage/account-edit",
        component: () => import("@/views/merchant/account/detail/index.vue"),
        hidden: true,
        meta: { title: "对公账户编辑", icon: "list" },
      },
      // {
      //   path: "/merchant-manage/account-apply",
      //   component: () => import("@/views/merchant/account/apply/index.vue"),
      //   hidden: true,
      //   meta: { title: "对公账户申请列表", icon: "list" },
      // },
      {
        path: "/merchant-manage/account-records",
        component: () => import("@/views/merchant/account/records/index.vue"),
        hidden: true,
        meta: { title: "操作记录", icon: "list" },
      },
      // {
      //   path: "/merchant-manage/account-review",
      //   component: () => import("@/views/merchant/account/review/index.vue"),
      //   hidden: true,
      //   meta: { title: "对公账户审核列表", icon: "list" },
      // },
      {
        path: "/merchant-manage/account-audit",
        component: () => import("@/views/merchant/account/audit/index.vue"),
        hidden: true,
        meta: { title: "对公账户审核", icon: "list" },
      },
    ],
  },
];

export default merchant_config;
