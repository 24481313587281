import Layout from "@/layout";

const oil_gun_config = [
  {
    path: "/oil-gun",
    component: Layout,
    hidden: true,
    children: [
      // {
      //   path: "/oil-gun/oil-list",
      //   component: () => import("@/views/oil-gun/list/index.vue"),
      //   hidden: true,
      //   meta: { title: "油枪管理", icon: "look" },
      // },
      {
        path: "/oil-gun/oil-record",
        component: () => import("@/views/oil-gun/record/index.vue"),
        hidden: true,
        meta: { title: "油枪价变更记录", icon: "look" },
      },
      // {
      //   path: "/oil-gun/oil-apply",
      //   component: () => import("@/views/oil-gun/apply/index.vue"),
      //   hidden: true,
      //   meta: { title: "油枪价申请", icon: "look" },
      // },
      {
        path: "/oil-gun/oil-apply-edit",
        component: () => import("@/views/oil-gun/apply-look/index.vue"),
        hidden: true,
        meta: { title: "查看-修改油枪价", icon: "look" },
      },
      {
        path: "/oil-gun/oil-apply-create",
        component: () => import("@/views/oil-gun/apply-look/index.vue"),
        hidden: true,
        meta: { title: "查看-新建油枪价", icon: "look" },
      },
      {
        path: "/oil-gun/oil-apply-stop",
        component: () => import("@/views/oil-gun/apply-look/index.vue"),
        hidden: true,
        meta: { title: "查看-停用油枪价", icon: "look" },
      },
      // {
      //   path: "/oil-gun/oil-review",
      //   component: () => import("@/views/oil-gun/review/index.vue"),
      //   hidden: true,
      //   meta: { title: "油枪价审核列表", icon: "look" },
      // },
      {
        path: "/oil-gun/oil-audit",
        component: () => import("@/views/oil-gun/audit/index.vue"),
        hidden: true,
        meta: { title: "油枪价审核-新建", icon: "look" },
      },
      {
        path: "/oil-gun/oil-audit-stop",
        component: () => import("@/views/oil-gun/audit/index.vue"),
        hidden: true,
        meta: { title: "油枪价审核-停用", icon: "look" },
      },
      {
        path: "/oil-gun/oil-audit-edit",
        component: () => import("@/views/oil-gun/audit/index.vue"),
        hidden: true,
        meta: { title: "油枪价审核-修改", icon: "look" },
      },
      {
        path: "/oil-gun/oil-review-create",
        component: () => import("@/views/oil-gun/review-look/index.vue"),
        hidden: true,
        meta: { title: "查看-新建油枪价", icon: "look" },
      },
      {
        path: "/oil-gun/oil-review-edit",
        component: () => import("@/views/oil-gun/review-look/index.vue"),
        hidden: true,
        meta: { title: "查看-修改油枪价", icon: "look" },
      },
      {
        path: "/oil-gun/oil-review-stop",
        component: () => import("@/views/oil-gun/review-look/index.vue"),
        hidden: true,
        meta: { title: "查看-停用油枪价", icon: "look" },
      },
    ],
  },
];

export default oil_gun_config;
