import Layout from "@/layout";

const printer_manage_router_config = [
  // {
  //   path: "/printer-manage",
  //   component: Layout,
  //   hidden: true,
  //   children: [
  //     {
  //       path: "/printer-manage/printer-list",
  //       component: () => import("@/views/printer-manage/list/index.vue"),
  //       hidden: true,
  //       meta: { title: "打印机列表", icon: "list" },
  //     },
  //   ],
  // },
  {
    path: "/printer-manage",
    component: Layout,
    hidden: true,
    children: [
      {
        path: "/printer-manage/printer-look",
        component: () => import("@/views/printer-manage/look/index.vue"),
        hidden: true,
        meta: { title: "打印机详情", icon: "look" },
      },
    ],
  },
  // {
  //   path: "/printer-manage",
  //   component: Layout,
  //   hidden: true,
  //   children: [
  //     {
  //       path: "/printer-manage/printer-create",
  //       component: () => import("@/views/printer-manage/create/index.vue"),
  //       hidden: true,
  //       meta: { title: "添加打印机", icon: "create" },
  //     },
  //   ],
  // },
  {
    path: "/printer-manage",
    component: Layout,
    hidden: true,
    children: [
      {
        path: "/printer-manage/printer-edit",
        component: () => import("@/views/printer-manage/create/index.vue"),
        hidden: true,
        meta: { title: "流量卡续期", icon: "edit" },
      },
    ],
  },
  // {
  //   path: "/printer-manage",
  //   component: Layout,
  //   hidden: true,
  //   children: [
  //     {
  //       path: "/printer-manage/printer-import",
  //       component: () => import("@/views/printer-manage/import/index.vue"),
  //       hidden: true,
  //       meta: { title: "批量导入", icon: "edit" },
  //     },
  //   ],
  // },
  {
    path: "/printer-manage",
    component: Layout,
    hidden: true,
    children: [
      {
        path: "/printer-manage/printer-records",
        component: () => import("@/views/printer-manage/records/index.vue"),
        hidden: true,
        meta: { title: "打印记录", icon: "edit" },
      },
    ],
  },
  // {
  //   path: "/printer-manage",
  //   component: Layout,
  //   hidden: true,
  //   children: [
  //     {
  //       path: "/printer-manage/printer-test",
  //       component: () => import("@/views/printer-manage/test/index.vue"),
  //       hidden: true,
  //       meta: { title: "打印测试", icon: "test" },
  //     },
  //   ],
  // },
];
export default printer_manage_router_config;
