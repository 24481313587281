import Layout from "@/layout";

const account_back_router_config = [
  {
    path: "/account-manage",
    component: Layout,
    hidden: true,
    children: [
      // {
      //   path: "/account-manage/back/list",
      //   component: () => import("@/views/account-back/list/index.vue"),
      //   hidden: true,
      //   meta: { title: "油站后结算账户", icon: "list" },
      // },
      {
        path: "/account-manage/back/center",
        component: () => import("@/views/account-back/center/index.vue"),
        hidden: true,
        meta: { title: "账户中心", icon: "list" },
      },
      {
        path: "/account-manage/back/review",
        component: () => import("@/views/account-back/review/index.vue"),
        hidden: true,
        meta: { title: "提现审核", icon: "list" },
      },
      {
        path: "/account-manage/back/cash",
        component: () => import("@/views/account-back/cash/index.vue"),
        hidden: true,
        meta: { title: "审核提现", icon: "list" },
      },
      {
        path: "/account-manage/back/mark",
        component: () => import("@/views/account-back/mark/index.vue"),
        hidden: true,
        meta: { title: "标记提现", icon: "list" },
      },
      {
        path: "/account-manage/back/detail",
        component: () => import("@/views/account-back/detail/index.vue"),
        hidden: true,
        meta: { title: "资金明细", icon: "list" },
      },
      {
        path: "/account-manage/back/download",
        component: () => import("@/views/account-back/download/index.vue"),
        hidden: true,
        meta: { title: "对账下载", icon: "list" },
      },
      {
        path: "/account-manage/back/look",
        component: () => import("@/views/account-back/detail/index.vue"),
        hidden: true,
        meta: { title: "查看明细", icon: "list" },
      },
    ],
  },
  {
    path: "/account-manage",
    component: Layout,
    hidden: true,
    children: [
      // {
      //   path: "/account-manage/front/list",
      //   component: () => import("@/views/account-front/list/index.vue"),
      //   hidden: true,
      //   meta: { title: "油站后结算账户", icon: "list" },
      // },
      {
        path: "/account-manage/front/listDetail",
        component: () => import("@/views/account-front/listDetail/index.vue"),
        hidden: true,
        meta: { title: "消返明细", icon: "list" },
      },
      {
        path: "/account-manage/front/center",
        component: () => import("@/views/account-front/center/index.vue"),
        hidden: true,
        meta: { title: "账户中心", icon: "list" },
      },
      {
        path: "/account-manage/front/detail",
        component: () => import("@/views/account-front/detail/index.vue"),
        hidden: true,
        meta: { title: "资金明细", icon: "list" },
      },
      {
        path: "/account-manage/front/pending",
        component: () => import("@/views/account-front/pending/index.vue"),
        hidden: true,
        meta: { title: "消返待处理列表", icon: "list" },
      },
      {
        path: "/account-manage/front/regression",
        component: () => import("@/views/account-front/regression/index.vue"),
        hidden: true,
        meta: { title: "消返", icon: "list" },
      },
      {
        path: "/account-manage/front/download",
        component: () => import("@/views/account-front/download/index.vue"),
        hidden: true,
        meta: { title: "对账下载", icon: "list" },
      },
      {
        path: "/account-manage/front/look",
        component: () => import("@/views/account-front/detail/index.vue"),
        hidden: true,
        meta: { title: "查看明细", icon: "list" },
      },
      {
        path: "/account-manage/front/recharge",
        component: () => import("@/views/account-front/recharge/index.vue"),
        hidden: true,
        meta: { title: "充值", icon: "list" },
      },
      {
        path: "/account-manage/front/records",
        component: () => import("@/views/account-front/records/index.vue"),
        hidden: true,
        meta: { title: "充值记录", icon: "list" },
      },
      {
        path: "/account-manage/front/return",
        component: () => import("@/views/account-front/return/index.vue"),
        hidden: true,
        meta: { title: "子账户充值回收", icon: "list" },
      },
    ],
  },
  {
    path: "/account-manage",
    component: Layout,
    hidden: true,
    children: [
      {
        path: "/account-manage/channel-account/channel-detail",
        component: () =>
          import("@/views/account-manage/channel-account/detailList/index.vue"),
        hidden: true,
        meta: { title: "对账明细", icon: "list" },
      },
    ],
  },
];
export default account_back_router_config;
