import request from "@/utils/request";

// 父渠道下拉列表
export function publicSelectList(data) {
  return request({
    url: "channel/channelParentManage/selectList",
    method: "post",
    data,
  });
}

// 子渠道下拉框
export function publicGetChannelList(parentId, facilitatorId) {
  return request({
    url: `channel/channelManage/getChannelList`,
    method: "get",
    params: {
      parentId, // 父渠道
      facilitatorId, // 服务商
    },
  });
}

// 订单编号获取订单详情
export function orderDetailGetOrderId(supplierOrderNo) {
  return request({
    url: `order/detail/getOrderId`,
    method: "get",
    params: {
      supplierOrderNo, // 订单编号
    },
  });
}

// 获取总行银行列表
export function mainBankList() {
  return request({
    url: `bank/list`,
    method: "get",
  });
}

// 获取银行支行列表
export function bankBranckList(mainBankName) {
  return request({
    url: `bank/branch/list`,
    method: "get",
    params: {
      mainBankName,
    },
  });
}

// 易宝商户账户余额查询 商户编号
export function yopMerchantAccountBalance(merchantNo) {
  return request({
    url: `yop/merchant/account/balance`,
    method: "get",
    params: {
      merchantNo,
    },
  });
}

// 记账簿账户余额查询 商户编号
export function yopBookAccountBalance() {
  return request({
    url: `yop/book/account/balance`,
    method: "get",
  });
}
