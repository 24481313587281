import Layout from "@/layout";

const gas_station_manage_router_config = [
  {
    path: "/gas-station",
    component: Layout,
    hidden: true,
    redirect: "/gas-station/list",
    meta: {
      title: "加油站管理",
      icon: "gas-station",
      noCache: false,
      link: null,
    },
    children: [
      // {
      //   path: "/gas-station/gas-create",
      //   component: () => import("@/views/gas-station-manage/create/index.vue"),
      //   hidden: true,
      //   meta: { title: "加油站创建", icon: "look" },
      // },
      {
        path: "/gas-station/gas-create-edit",
        component: () => import("@/views/gas-station-manage/create/index.vue"),
        hidden: true,
        meta: { title: "加油站创建编辑", icon: "look" },
      },
      {
        path: "/gas-station/gas-look",
        component: () => import("@/views/gas-station-manage/create/index.vue"),
        hidden: true,
        meta: { title: "加油站查看", icon: "look" },
      },
      {
        path: "/gas-station/gas-edit",
        component: () => import("@/views/gas-station-manage/create/index.vue"),
        hidden: true,
        meta: { title: "加油站编辑", icon: "edit" },
      },
      {
        path: "/gas-station/look",
        component: () => import("@/views/gas-station-manage/create2/index.vue"),
        hidden: true,
        meta: { title: "加油站创建", icon: "edit" },
      },
      {
        path: "/gas-station/edit",
        component: () => import("@/views/gas-station-manage/create2/index.vue"),
        hidden: true,
        meta: { title: "加油站编辑", icon: "edit" },
      },
      {
        path: "/gas-station/edit-only",
        component: () =>
          import("@/views/gas-station-manage/create2/index2.vue"),
        hidden: true,
        meta: { title: "加油站编辑", icon: "edit" },
      },
      // {
      //   path: "/gas-station/gas-inspect",
      //   component: () => import("@/views/gas-station/inspect/index.vue"),
      //   hidden: true,
      //   meta: { title: "加油站发布检查", icon: "edit" },
      // },
      {
        path: "/gas-station/premia-info",
        component: () =>
          import("@/views/gas-station-manage/premia-info/index.vue"),
        hidden: true,
        meta: { title: "溢价信息", icon: "edit" },
      },
    ],
  },
];

export default gas_station_manage_router_config;
