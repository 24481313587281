export const baseDataConfig = {
  headerList: [
    {
      value: 7,
      title: "续签",
      isBtn: true,
    },
    {
      value: 9,
      title: "补充",
      isBtn: true,
    },
  ],
  bodyList: [
    {
      apiKeyName: "facilitatorId",
      value: 1,
      isLook: true,
    },
    {
      apiKeyName: "contractNum",
      value: "",
      isLook: true,
    },
    {
      apiKeyName: "contractStartDate",
      apiKeyName2: "contractEndDate",
      value: [],
      isLook: true,
      type: "date",
    },
    {
      apiKeyName: "contractFile",
      value: "",
      isLook: true,
    },
    {
      apiKeyName: "extraContract",
      value: [],
      isLook: true,
      type: "filemore",
    },
  ],
};

export const test_api_data = [
  {
    facilitatorId: 1,
    contractNum: "11111",
    contractStartDate: "2012-12-20",
    contractEndDate: "2013-04-20",
    contractFile: "222222222.pdf",
    extraContract: "222222222.pdf,33333333.pdf,444444.pdf",
  },
  {
    facilitatorId: 2,
    contractNum: "111112222222",
    contractStartDate: "2012-06-20",
    contractEndDate: "2013-03-20",
    contractFile: "3333333.pdf",
    extraContract: "77777.pdf,33333333.pdf,444444.pdf",
  },
];
