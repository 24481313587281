<template>
  <div class="page_box">
    <div class="table_export" v-if="props.isexport">
      <el-button type="primary" @click="onExport">导出当前页</el-button>
    </div>

    <div class="pagination_box2" v-if="state.pageSize >= 100">
      <el-pagination
        background
        @current-change="onCurrentChange"
        @size-change="onSizeList"
        :page-sizes="state.pageSizes"
        v-model:current-page="state.pageCurrent"
        layout="total, sizes, prev, pager, next"
        v-model:page-size="state.pageSize"
        :hide-on-single-page="true"
        :total="props.total || 0"
      />
    </div>

    <el-table
      :data="props.tableBodyList"
      :border="true"
      :loading="props.loading"
      :stripe="true"
      :span-method="props.span_method"
      @selection-change="selectionChange"
      :cell-class-name="onCell"
      :max-height="3000"
    >
      <el-table-column
        type="index"
        width="60"
        label="序号"
        v-if="
          !(
            props.pageConfig.prefix_type ||
            props.pageConfig.prefix_type == 'empty'
          )
        "
      />

      <el-table-column
        type="selection"
        width="60"
        v-if="props.pageConfig.prefix_type === 'selection'"
      />
      <el-table-column
        :prop="item.id"
        :label="item.title"
        v-for="item in props.tableTitleList"
        :key="item.id"
        :min-width="item.width"
        :fixed="item.fixed"
      >
        <template #default="{ row }">
          <div
            class="table_link"
            :class="{ active: row[item.id] !== '-' }"
            v-if="
              props.pageConfig.item_link &&
              props.pageConfig.item_link.includes(item.id)
            "
          >
            <slot name="link" :data="row" :key="item.id">
              {{ row[item.id] }}
            </slot>
          </div>
          <template v-else-if="item.slot_name">
            <slot :name="item.slot_name" :data="row" :key="item.id"></slot>
          </template>
          <template v-else>{{ row[item.id] }}</template>
        </template>
      </el-table-column>
      <el-table-column
        fixed="right"
        label="操作"
        :width="props.pageConfig.control_min_width || 136"
        v-if="props.pageConfig.delete_url || props.pageConfig.edit_url"
      >
        <template #default="scope">
          <div class="table_button">
            <slot name="edit" :data="scope.row"> </slot>
          </div>
        </template>
      </el-table-column>
    </el-table>

    <div class="pagination_box">
      <el-pagination
        background
        @current-change="onCurrentChange"
        @size-change="onSizeList"
        :page-sizes="state.pageSizes"
        v-model:current-page="state.pageCurrent"
        layout="total, sizes, prev, pager, next"
        v-model:page-size="state.pageSize"
        :hide-on-single-page="true"
        :total="props.total || 0"
      />
    </div>
    <el-backtop :right="100" :bottom="100" v-if="state.pageSize >= 100" />
  </div>
</template>

<script setup name="TableCom">
import { onMounted, reactive, toRefs, ref, watch, computed } from "vue";
import { ElMessage, ElMessageBox } from "element-plus";
import { codeToText, regionData } from "element-china-area-data";
import { useRouter, useRoute } from "vue-router";
import { queryDelete } from "@/api/petrol-printer/index";
import {
  onXlsxDownload,
  globalQueryJson,
  globalQueryText,
} from "@/utils/date_tools";
import { request_timeout_config } from "@/config/system_config.js";

const api = "";

const props = defineProps({
  tableTitleList: Array,
  tableBodyList: Array,
  pageConfig: Object,
  total: Number,
  loading: Boolean,
  span_method: {
    type: Function,
    default: () => {},
  },
  table_page: {
    type: Number,
    default: () => {
      const data = globalQueryJson();
      if (data.pageCurrent) {
        return Number(data.pageCurrent);
      }
      return 1;
    },
  },
  resetPage1: {
    type: Boolean,
    default: true,
  },
  isexport: {
    type: Boolean,
    default: true,
  },
  // 测试使用，用于隐藏分页数量
  pagingTest: {
    type: Boolean,
    default: false,
  },
});

const emit = defineEmits(["update-list", "selection-change"]);

const router = useRouter();
const route = useRoute();

function onExport() {
  const { tableTitleList, tableBodyList } = props;
  const titles = tableTitleList.map((item) => item.title);
  const ids = tableTitleList.map((item) => item.id);
  const bodys = tableBodyList.map((item) => {
    const obj = {};
    ids.forEach((key) => {
      obj[key] = String(item[key] || "");
    });
    return obj;
  });
  const exportName = route.meta.title || "列表";
  onXlsxDownload(titles, bodys, exportName);
}
const state = reactive({
  pageSize: props.pageConfig.page_size,
  pageCurrent: props.table_page,
  pageSizes: computed(() => {
    const isTimeout = request_timeout_config.includes(route.path);
    let sizes = [10, 50, 100, 200, 300];
    if (isTimeout) {
      sizes = [10, 50, 100, 200, 300, 1000];
    }
    return sizes;
  }),
});

function onSizeList() {
  state.pageCurrent = 1;
  getList();
  ElMessage({
    type: "success",
    message: "更新每页条数成功!",
  });
}

function getList() {
  emit("update-list");
}

function selectionChange(e) {
  if (props.pageConfig.prefix_type === "selection") {
    emit("selection-change", e);
  }
}

const onEdit = (row) => {
  router.push({
    path: props.pageConfig.edit_url,
    query: { id: row.id },
  });
};

function onCell({ row, column, rowIndex, columnIndex }) {
  // console.log(row, 1, column, rowIndex, columnIndex)
  // businessStatus
  if (props.pageConfig.prefix_type === "selection") {
    if (columnIndex === 0) {
      if (row.status == 1) {
        // 确认
        return "selection_checkout";
      }
    }
  }
}

const onDelete = async (row) => {
  const dataConfig = {
    confirmButtonText: "确定",
    cancelButtonText: "取消",
    type: "error",
  };
  ElMessageBox.confirm(
    `是否确定删除 ${row[props.pageConfig.delete_title]}`,
    "确认删除",
    dataConfig
  ).then(async () => {
    let obj = { id: row.id };
    if (props.pageConfig.delete_key) {
      obj = {};
      if (props.pageConfig.delete_value) {
        obj[props.pageConfig.delete_key] = row[props.pageConfig.delete_value];
      } else {
        obj[props.pageConfig.delete_key] = row[props.pageConfig.delete_key];
      }
    }
    await queryDelete(props.pageConfig.delete_url, obj);
    getList();
    ElMessage({
      type: "success",
      message: "删除成功",
    });
  });
};

// 分页是否展示判断
const isPaging = computed(() => {
  return !(props.pageConfig.page_size >= props.total);
});

function onCurrentChange() {
  const url = globalQueryText("", {
    ...globalQueryJson(),
    pageCurrent: state.pageCurrent,
  });
  router.replace(url);
  getList();
}

watch(
  () => props.resetPage1,
  (newValue) => {
    state.pageCurrent = 1;
    getList();
  },
  {
    // immediate: true,
    deep: true,
  }
);

defineExpose({
  ...toRefs(state),
});
</script>

<style scoped lang="scss">
@import url(./style.scss);
</style>
