<template>
  <div class="page_box page_header">
    <div class="table_box">
      <div class="table_item">序号</div>
      <div class="table_item">{{ props.tableTitleList[0].title }}</div>
      <div class="table_item">{{ props.tableTitleList[1].title }}</div>
      <div class="table_item table_page">
        <div class="table_box">
          <div class="table_item">{{ props.tableTitleList[2].title }}</div>
          <div class="table_item">{{ props.tableTitleList[3].title }}</div>
          <div class="table_item">{{ props.tableTitleList[4].title }}</div>
          <div class="table_item">{{ props.tableTitleList[5].title }}</div>
          <div class="table_item">{{ props.tableTitleList[6].title }}</div>
          <div class="table_item">{{ props.tableTitleList[7].title }}</div>
          <div class="table_item">{{ props.tableTitleList[8].title }}</div>
          <div class="table_item">{{ props.tableTitleList[9].title }}</div>
          <div class="table_item">操作</div>
        </div>
      </div>
    </div>
  </div>
  <div class="page_box page_main" v-loading="props.loading">
    <div
      class="table_box"
      v-for="(item, index) in props.tableBodyList"
      :key="index"
    >
      <div class="table_item">{{ index + 1 }}</div>
      <div class="table_item">
        {{ item.gbPriceGroupVo && item.gbPriceGroupVo.provinceShow }}
      </div>
      <div class="table_item">
        {{ item.gbPriceGroupVo && item.gbPriceGroupVo.oilTypeShow }}
      </div>
      <div class="table_item table_page">
        <div
          class="table_box"
          v-for="(item2, index2) in item.gbPriceApplySubrecordVos"
          :key="index2"
        >
          <div class="table_item">{{ item2.oilNum }}</div>
          <div class="table_item">{{ item2.nowPrice }}</div>
          <div class="table_item">{{ item2.updatedPrice }}</div>
          <div class="table_item">{{ item2.effectiveDate }}</div>
          <div class="table_item">{{ item2.applyTypeName }}</div>
          <div class="table_item">{{ item2.applyStatusName }}</div>
          <div class="table_item">{{ item2.createName }}</div>
          <div class="table_item">{{ item2.createTime }}</div>
          <div class="table_item table_btn">
            <slot name="btn" :data="item2"></slot>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="pagination_box">
    <el-pagination
      background
      @current-change="getList"
      v-model:current-page="state.pageCurrent"
      layout="prev, pager, next"
      :page-size="state.pageSize"
      :total="props.total"
      :hide-on-single-page="true"
    />
  </div>
</template>

<script setup lang="ts">
import { onMounted, ref, toRefs, reactive, watch, computed } from "vue";
import { ElMessage, ElMessageBox } from "element-plus";
import { codeToText, regionData } from "element-china-area-data";
// import BigNumber from "bignumber.js";
import {
  globalGetdate,
  onSelectText,
  onRequestResult,
  onSelectFormat,
  onEmptyValueFormat,
} from "@/utils/date_tools";
import { useRouter, useRoute } from "vue-router";
import useUserStore from "@/store/modules/user";
// import { User } from "@element-plus/icons-vue";
// import { } from "@/config/selectConfig";
// import { login } from "@/api/ucenter/login";

const image_domain = import.meta.env.VITE_IMG_UPLOAD_API; // 图片地址
const options = ref(regionData); // 地理位置选项数据源
const emits = defineEmits(["update-list"]);
const props: any = defineProps({
  tableTitleList: Array,
  tableBodyList: Array,
  pageConfig: Object,
  total: Number,
  loading: Boolean,
});
const userStore = useUserStore();

const router = useRouter();
const route = useRoute();
const detail_id = route.query.id; // query id

const state = reactive({
  pageSize: props.pageConfig.page_size,
  pageCurrent: 1,
}) as any;

onMounted(async () => {
  init();
});

async function init() {}

function getList() {
  emits("update-list");
}

function onReset() {
  state.input = "";
}

// const computed_sample = computed(() => {
//   return "end";
// });

// userStore.setVuex('user_id', res.id); // 设置 获取 userStore.user_id

// watch(
//   () => route.fullPath,
//   (newValue) => {},
//   {
//     immediate: true,
//     deep: true,
//   }
// );

defineExpose({
  ...toRefs(state),
});
</script>

<style scoped lang="scss">
@import url(./style.scss);
</style>
