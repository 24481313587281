// 营销活动
import Layout from "@/layout";

const gas_station_ad_router_config = [
  {
    path: "/gas-station-ad",
    component: Layout,
    hidden: true,
    children: [
      // {
      //   path: "/gas-station-ad/gas-station-ad-list",
      //   component: () => import("@/views/gas-station-ad/list/index.vue"),
      //   hidden: true,
      //   meta: { title: "充值返现优惠", icon: "list" },
      // },
      {
        path: "/gas-station-ad/gas-station-ad-create",
        component: () => import("@/views/gas-station-ad/create/index.vue"),
        hidden: true,
        meta: { title: "添加规则", icon: "list" },
      },
      {
        path: "/gas-station-ad/gas-station-ad-look",
        component: () => import("@/views/gas-station-ad/create/index.vue"),
        hidden: true,
        meta: { title: "查看规则", icon: "list" },
      },
      // {
      //   path: "/gas-station-ad/gas-station-cc-list",
      //   component: () => import("@/views/gas-station-ad/ccList/index.vue"),
      //   hidden: true,
      //   meta: { title: "消费返现优惠", icon: "list" },
      // },
      {
        path: "/gas-station-ad/gas-station-cc-create",
        component: () => import("@/views/gas-station-ad/ccCreate/index.vue"),
        hidden: true,
        meta: { title: "添加规则", icon: "list" },
      },
      {
        path: "/gas-station-ad/gas-station-cc-look",
        component: () => import("@/views/gas-station-ad/ccCreate/index.vue"),
        hidden: true,
        meta: { title: "查看规则", icon: "list" },
      },
    ],
  },
];
export default gas_station_ad_router_config;
