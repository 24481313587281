<template>
  <div class="form_tooltip_box">
    <div class="form_tooltip_content">
      <slot></slot>
    </div>
    <div class="form_tooltip_tooltip" v-if="props.modelValue.tooltip">
      <el-tooltip
        effect="dark"
        :content="props.modelValue.tooltip"
        placement="top-start"
        :raw-content="true"
      >
        <svg-icon icon-class="query" />
      </el-tooltip>
    </div>
  </div>
</template>

<script setup lang="ts">
const emits = defineEmits(["update:modelValue"]);
const props = defineProps({
  modelValue: null,
});
</script>

<style scoped lang="scss">
@import url(./style.scss);
</style>
