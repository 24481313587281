import Layout from "@/layout";

const other_router_config = [
  {
    path: "/system",
    component: Layout,
    hidden: true,
    children: [
      // {
      //   path: "/system/secure-config",
      //   component: () => import("@/views/system/secure-config/index.vue"),
      //   hidden: true,
      //   meta: { title: "安全配置", icon: "list" },
      // },
    ],
  },
];
export default other_router_config;
