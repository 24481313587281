import Layout from "@/layout";

const report_forms_router_config = [
  {
    path: "/report-forms",
    component: Layout,
    hidden: true,
    children: [
      // {
      //   path: "/report-forms/station-zk",
      //   component: () => import("@/views/report-forms/station-zk/index.vue"),
      //   hidden: true,
      //   meta: { title: "油站折扣", icon: "list" },
      // },
      // {
      //   path: "/report-forms/station-jl",
      //   component: () => import("@/views/report-forms/station-jl/index.vue"),
      //   hidden: true,
      //   meta: { title: "油站净利", icon: "list" },
      // },
      // {
      //   path: "/report-forms/order-jl",
      //   component: () => import("@/views/report-forms/order-jl/index.vue"),
      //   hidden: true,
      //   meta: { title: "订单净利", icon: "list" },
      // },
      // {
      //   path: "/report-forms/order-ml",
      //   component: () => import("@/views/report-forms/order-ml/index.vue"),
      //   hidden: true,
      //   meta: { title: "订单毛利", icon: "list" },
      // },
    ],
  },
];
export default report_forms_router_config;
