<template>
  <div>
    <div v-show="props.islook">
      <div class="upload_mul" v-if="props.type == 1">
        <div class="upload_preview" v-for="(item, index) in file_list">
          <ImagePreview
            :width="140"
            :height="140"
            :src="`${image_domain}/${item}`"
            class="upload_img active upload_preview_img"
          />
        </div>
      </div>
      <div class="mul_box mul_box2" v-if="props.type == 3">
        <div class="mul_item" v-for="(item, index) in file_list" :key="index">
          <div class="mul_text" @click="onPdf(item)">{{ item }}</div>
        </div>
      </div>
    </div>
    <div class="picture_box" v-show="!props.islook">
      <div class="upload_mul" v-if="props.type == 1">
        <div class="upload_preview" v-for="(item, index) in file_list">
          <ImagePreview
            :width="140"
            :height="140"
            :src="`${image_domain}/${item}`"
            class="upload_img active upload_preview_img"
          />
          <div
            class="mul_shut upload_preview_shut"
            @click="onShut(item, index)"
          >
            <el-icon>
              <Close />
            </el-icon>
          </div>
        </div>
        <div class="upload_img" @click="onUploadImg"></div>
      </div>
      <el-upload
        :accept="props.accept"
        name="images"
        :action="`${apiBaseUrl}/fileOss/upload`"
        :show-file-list="false"
        :on-success="handleAvatarSuccess"
        :before-upload="beforeAvatarUpload"
        :on-error="handleAvatarError"
        :headers="headers"
      >
        <!-- <slot :file_list="file_list">
        
      </slot> -->
        <template #trigger>
          <div class="upload_img" ref="upload_img" v-show="false"></div>
          <el-button type="warning" v-if="props.type == 3">上传文件</el-button>
        </template>
      </el-upload>
      <div class="upload_tip" v-if="props.type != 2">
        {{ props.text }}
      </div>
      <div class="mul_box" v-if="props.type == 3">
        <div class="mul_item" v-for="(item, index) in file_list" :key="index">
          <div class="mul_text" @click="onPdf(item)">{{ item }}</div>
          <div class="mul_shut" @click="onShut(item, index)">
            <el-icon><Close /></el-icon>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script setup>
import { ref, watch } from "vue";
import { ElMessage, ElLoading } from "element-plus";
const apiBaseUrl = import.meta.env.VITE_APP_BASE_API;
import LoadingCom from "@/components/LoadingCom/index";
import { getToken } from "@/utils/auth";
import { Close } from "@element-plus/icons-vue";

const image_domain = import.meta.env.VITE_IMG_UPLOAD_API;

const emits = defineEmits(["success", "update:modelValue"]);

const headers = ref({
  Authorization: "Bearer " + getToken(),
});

const props = defineProps({
  type: {
    type: Number,
    default: 1,
  },
  accept: {
    type: String,
    default: "image/jpeg,image/png",
  },
  text: {
    type: String,
    default: "支持JPG/PNG格式，且不超过10MB",
  },
  file_list: {
    type: Array,
    default: [],
  },
  islook: {
    type: Boolean,
    default: false,
  },
});

const file_list = ref([]);
let loading = null;

const handleAvatarSuccess = (response, uploadFile) => {
  const { fileKey, fileurl } = response.data;
  file_list.value.push(fileKey);
  loading.close();

  emits("success", file_list.value);
  emits("update:modelValue", file_list.value);
};

const beforeAvatarUpload = (rawFile) => {
  if (rawFile.size / 1024 / 1024 > 10) {
    ElMessage.error("图片大小不能超过10MB!");
    return false;
  }

  if (props.type != 3) {
    // 图片格式检测，只支持jpg 和 png
    // 这里为了解决微信浏览器不支持 access 属性
    const accessList = ["image/jpeg", "image/png"];
    const is = accessList.includes(rawFile.type);
    if (!is) {
      ElMessage.error(`${props.text}`);
      return false;
    }
  } else {
    // pdf
    if (rawFile.type != "application/pdf") {
      ElMessage.error(`${props.text}`);
      return false;
    }
  }

  loading = ElLoading.service({
    lock: true,
    text: "上传中...",
    background: "rgba(0, 0, 0, 0.7)",
  });

  // if (rawFile.type !== "image/jpeg") {
  //   ElMessage.error("Avatar picture must be JPG format!");
  //   return false;
  // }
  // if (rawFile.type !== 'image/jpeg') {
  //     ElMessage.error('Avatar picture must be JPG format!')
  //     return false
  // } else if (rawFile.size / 1024 / 1024 > 2) {
  //     ElMessage.error('Avatar picture size can not exceed 2MB!')
  //     return false
  // }
  return true;
};

const handleAvatarError = (error) => {
  const data = JSON.parse(error["message"]);
  ElMessage.error(`上传失败：${data.message}`);
  loading.close();
};

function onPdf(url) {
  const is = String(url).includes("http");
  const url2 = `${image_domain}/${url}`;
  window.open(is ? url : url2, url);
}

function onShut(key, idx) {
  file_list.value.splice(idx, 1);
  emits("update:modelValue", file_list.value);
}
const upload_img = ref(null);
function onUploadImg() {
  upload_img.value.click();
}

// 图片地址两个平台兼容
function urlFormat(url) {
  const is = String(url).includes("http");
  const url2 = `${image_domain}/${url}`;
  return is ? url : url2;
}

watch(
  () => props,
  (newValue) => {
    if (newValue.file_list) {
      const list = newValue.file_list.map((item) => urlFormat(item));
      const list2 = list.filter(
        (item) => item.lastIndexOf("/") != item.length - 1
      );
      file_list.value = list2;
    } else {
      file_list.value = "";
    }
  },
  {
    immediate: true,
    deep: true,
  }
);

defineExpose({
  file_list,
});
</script>

<style scoped lang="scss">
@import url(./style.scss);
</style>
