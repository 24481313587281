<template>
  <div class="page_box">
    <el-card>
      <template #header>
        <div class="card_box">
          <div class="card_box">
            <div class="card_header">{{ props.title }}</div>
          </div>
          <div></div>
        </div>
      </template>
      <el-row
        :gutter="20"
        class="box_border"
        v-for="(item, index) in props.list"
        :key="index"
      >
        <el-col :span="24">
          <el-form-item
            label="服务商名称："
            class="border_one"
            label-width="100"
            >{{ item.facilitatorName }}</el-form-item
          >
        </el-col>
        <el-col :span="8">
          <el-form-item label="有效期：" label-width="100">{{ item.contractStartDate }} 至 {{ item.contractEndDate }}</el-form-item>
        </el-col>
        <el-col :span="8">
          <el-form-item label="合同编号：" label-width="100">{{item.contractNum}}</el-form-item>
        </el-col>
        <el-col :span="8">
          <el-form-item label="状态：" label-width="100">{{ item.effectStatusDesc }}</el-form-item>
        </el-col>
        <el-col :span="8">
          <el-form-item label="主合同" :label-width="100">
            <PictureUploadComOnly
              accept=".pdf"
              :type="3"
              text="支持 PDF 格式，且不超过10MB"
              :img_key="item.contractFile"
              :islook="true"
            />
          </el-form-item>
        </el-col>
        <el-col :span="8">
          <el-form-item label="从属合同" :label-width="100">
            <PictureUploadComMultiple
              accept=".pdf"
              :type="3"
              text="支持 PDF 格式，且不超过10MB"
              :file_list="item.extraContractArr"
              :islook="true"
            />
          </el-form-item>
        </el-col>
      </el-row>
    </el-card>
  </div>
</template>

<script setup lang="ts">
import { onMounted, ref, reactive, watch, computed } from "vue";
import { ElMessage, ElMessageBox } from "element-plus";
import { codeToText, regionData } from "element-china-area-data";
// import BigNumber from "bignumber.js";
import {
  globalGetdate,
  onSelectText,
  onRequestResult,
  onSelectFormat,
  onEmptyValueFormat,
  onObjectCreate,
  onBarreFormat,
  getOliNumberText,
  onInputFormat,
} from "@/utils/date_tools";
import { useRouter, useRoute } from "vue-router";
import useUserStore from "@/store/modules/user";
// import { User } from "@element-plus/icons-vue";
// import { } from "@/config/selectConfig";
// import { login } from "@/api/ucenter/login";
const { proxy } = getCurrentInstance();

const image_domain = import.meta.env.VITE_IMG_UPLOAD_API; // 图片地址
const options = ref(regionData); // 地理位置选项数据源
const emits = defineEmits(["success"]);
const props: any = defineProps({
  title: {
    type: String,
    default: "上传合同",
  },
  list: {
    type: Array,
    default: [],
  },
});
const userStore = useUserStore();

const router = useRouter();
const route = useRoute();
const detail_id = route.query.id; // query id

const state = reactive({
  input: "",
}) as any;

onMounted(async () => {
  init();
});

async function init() {}

function onQuery() {}

function onReset() {
  state.input = "";
}

// const computed_sample = computed(() => {
//   return "end";
// });

// userStore.setVuex('user_id', res.id); // 设置 获取 userStore.user_id

// watch(
//   () => route.fullPath,
//   (newValue) => {},
//   {
//     immediate: true,
//     deep: true,
//   }
// );

// defineExpose({
//   id: 1,
// });
</script>

<style scoped lang="scss">
@import url(./style.scss);
</style>
