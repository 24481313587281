<template>
  <div class="page_box">
    <div class="table_header">
      <div class="table_title">{{ props.title }}</div>
      <div class="table_icon" @click="onEdit">
        <svg-icon icon-class="edit" />
      </div>
    </div>
    <el-dialog
      v-model="state.isDialog"
      :title="`批量操作${props.title}`"
      append-to-body
    >
      <el-form-item label="折扣相同" label-width="90">
        <el-radio-group v-model="state.issame">
          <el-radio :label="true">是</el-radio>
          <el-radio :label="false">否</el-radio>
        </el-radio-group>
      </el-form-item>
      <el-input
        v-model="state.input"
        :rows="10"
        type="textarea"
        placeholder="请输入批量添加的内容"
        @focus="onFocus"
      />
      <template #footer>
        <el-button @click="state.isDialog = false">取消</el-button>
        <el-button type="primary" @click="onSave">确定</el-button>
      </template>
    </el-dialog>
  </div>
</template>

<script setup lang="ts">
import { reactive } from "vue";
import { ElMessage } from "element-plus";

const emits = defineEmits(["success"]);
const props = defineProps({
  title: String,
});

const state = reactive({
  input: "",
  isDialog: false,
  issame: false,
}) as any;

function onEdit() {
  state.isDialog = true;
}

function onSave() {
  const data = state.input.trim();
  if (!data) {
    ElMessage.error("请添加数据!");
    return false;
  }
  const list = data.split("\n").map((item) => {
    const num = parseFloat(item.trim());
    let text = String(num);
    const isPoint = text.includes(".");
    if (isPoint && Number(text) < 10) {
      const arr = text.split(".");
      const left = arr[0].slice(0, 1);
      const right = arr[1].slice(0, 3);
      text = `${left}.${right}`;
    } else {
      text = text.slice(0, 2);
      text = Number(text) >= 10 ? "10" : text;
    }
    return isNaN(num) ? 0 : text;
  });
  state.isDialog = false;
  emits("success", {
    list,
    issame: state.issame,
  });
}

function onFocus(e) {
  e.currentTarget.select();
}
</script>

<style scoped lang="scss">
@import url(./style.scss);
</style>
