<template>
  <div>
    <div v-show="props.islook">
      <div v-if="props.type == 1">
        <div
          class="create_pdf"
          @click="onPdf(file_key)"
          v-if="isPdfImage(file_key)"
        >
          <div class="create_pdf_item">{{ imageUrl }}</div>
        </div>
        <ImagePreview :width="140" :height="140" :src="imageUrl" v-else />
      </div>
      <div v-else-if="props.type == 2">
        <ImagePreview :width="80" :height="40" :src="imageUrl" />
      </div>
      <div v-else-if="props.type == 3">
        <div class="create_pdf" @click="onPdf(file_key)">
          <div class="create_pdf_item">{{ file_key }}</div>
        </div>
      </div>
    </div>
    <div class="picture_box" v-show="!props.islook">
      <el-upload
        :accept="props.accept"
        name="images"
        :action="`${apiBaseUrl}/fileOss/upload`"
        :show-file-list="false"
        :on-success="handleAvatarSuccess"
        :before-upload="beforeAvatarUpload"
        :on-error="handleAvatarError"
        :headers="headers"
      >
        <slot :imageUrl="imageUrl" :file_key="file_key">
          <div v-if="props.type == 1">
            <div
              class="upload_img active"
              :style="`background-image:url(${imageUrl});`"
              v-show="imageUrl"
            ></div>
            <div class="upload_img" v-show="!imageUrl"></div>
          </div>
          <div v-else-if="props.type == 2">
            <div
              class="upload_img_2"
              v-show="imageUrl"
              :style="`background-image:url(${imageUrl});`"
            ></div>
            <div class="upload_img_2" v-show="!imageUrl">
              <svg-icon icon-class="img" />
            </div>
          </div>
          <div v-else-if="props.type == 3">
            <div class="upload_text" v-show="imageUrl">{{ file_key }}</div>
            <el-button type="warning" v-show="!imageUrl">上传文件</el-button>
          </div>
        </slot>
      </el-upload>
      <div class="upload_tip" v-if="props.type != 2">
        {{ props.text }}
      </div>
      <div class="look_img" :class="{ active: isLook }">
        <div class="look_img_bg"></div>
        <div class="look_img_loading"><LoadingCom /></div>
        <div
          class="look_img_box"
          :style="`background-image:url(${look_url});`"
        ></div>
        <div class="look_img_shut" @click="isLook = false"></div>
      </div>
    </div>
  </div>
</template>
<script setup>
import { ref, watch } from "vue";
import { ElMessage, ElLoading } from "element-plus";
const apiBaseUrl = import.meta.env.VITE_APP_BASE_API;
import LoadingCom from "@/components/LoadingCom/index";
import { getToken } from "@/utils/auth";

const image_domain = import.meta.env.VITE_IMG_UPLOAD_API;

const emits = defineEmits(["success", "update:modelValue"]);

const headers = ref({
  Authorization: "Bearer " + getToken(),
});

const props = defineProps({
  img_url: String,
  img_key: String,
  type: {
    type: Number,
    default: 1,
  },
  accept: {
    type: String,
    default: "image/jpeg,image/png",
  },
  text: {
    type: String,
    default: "支持JPG/PNG格式，且不超过10MB",
  },
  islook: {
    type: Boolean,
    default: false,
  },
  size: {
    type: Number,
    default: 10,
  },
});

const isLook = ref(false);
const look_url = ref("");
const imageUrl = ref("");
const file_key = ref("");
let loading = null;

// 大图片模式，查看模式兼容pdf格式
function isPdfImage(url) {
  return String(url).toLocaleLowerCase().includes(".pdf");
}

const handleAvatarSuccess = (response, uploadFile) => {
  const { fileKey, fileurl } = response.data;
  file_key.value = fileKey;
  imageUrl.value = `${image_domain}/${fileKey}`;
  loading.close();

  emits("success", file_key.value);
  emits("update:modelValue", file_key.value);
};

const beforeAvatarUpload = (rawFile) => {
  if (rawFile.size / 1024 / 1024 > props.size) {
    ElMessage.error(`图片大小不能超过${props.size}MB!`);
    return false;
  }
  if (props.type != 3) {
    // 图片格式检测，只支持jpg 和 png
    // 这里为了解决微信浏览器不支持 access 属性
    const accessList = ["image/jpeg", "image/png"];
    const is = accessList.includes(rawFile.type);
    if (!is) {
      ElMessage.error(`${props.text}`);
      return false;
    }
  } else {
    // pdf
    if (rawFile.type != "application/pdf") {
      ElMessage.error(`${props.text}`);
      return false;
    }
  }

  loading = ElLoading.service({
    lock: true,
    text: "上传中...",
    background: "rgba(0, 0, 0, 0.7)",
  });

  // if (rawFile.type !== "image/jpeg") {
  //   ElMessage.error("Avatar picture must be JPG format!");
  //   return false;
  // }
  // if (rawFile.type !== 'image/jpeg') {
  //     ElMessage.error('Avatar picture must be JPG format!')
  //     return false
  // } else if (rawFile.size / 1024 / 1024 > 2) {
  //     ElMessage.error('Avatar picture size can not exceed 2MB!')
  //     return false
  // }
  return true;
};

const handleAvatarError = (error) => {
  const data = JSON.parse(error["message"]);
  ElMessage.error(`上传失败：${data.message}`);
  loading.close();
};

function onLook(url) {
  if (url) {
    let url2 = url;
    if (url.includes("?")) {
      url2 = url.split("?")[0];
    }
    look_url.value = urlFormat(url2);
    isLook.value = true;
  }
}

// 查看pdf
function onPdf(url) {
  window.open(urlFormat(url), url);
}

// 图片地址两个平台兼容
function urlFormat(url) {
  const is = String(url).includes("http");
  const url2 = `${image_domain}/${url}`;
  return is ? url : url2;
}

watch(
  () => props,
  (newValue) => {
    if (newValue.img_key) {
      imageUrl.value = urlFormat(newValue.img_key);
      file_key.value = urlFormat(newValue.img_key);
    } else {
      imageUrl.value = "";
      file_key.value = "";
    }
  },
  {
    immediate: true,
    deep: true,
  }
);

defineExpose({
  file_key,
  imageUrl,
});
</script>

<style scoped lang="scss">
@import url(./style.scss);
</style>
