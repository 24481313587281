import Layout from "@/layout";

const business_manage_router_config = [
  {
    path: "/business-manage",
    component: Layout,
    hidden: true,
    children: [
      {
        path: "/business-manage/business-list",
        component: () => import("@/views/business-manage/list/index.vue"),
        hidden: true,
        meta: { title: "商户列表", icon: "list" },
      },
    ],
  },
  {
    path: "/business-manage",
    component: Layout,
    hidden: true,
    children: [
      {
        path: "/business-manage/business-look",
        component: () => import("@/views/business-manage/create/index.vue"),
        hidden: true,
        meta: { title: "商户-查看详情", icon: "look" },
      },
    ],
  },
  {
    path: "/business-manage",
    component: Layout,
    hidden: true,
    children: [
      {
        path: "/business-manage/business-create",
        component: () => import("@/views/business-manage/create/index.vue"),
        hidden: true,
        meta: { title: "商户入驻", icon: "create" },
      },
    ],
  },
  {
    path: "/business-manage",
    component: Layout,
    hidden: true,
    children: [
      {
        path: "/business-manage/business-edit",
        component: () => import("@/views/business-manage/create/index.vue"),
        hidden: true,
        meta: { title: "编辑商户信息", icon: "edit" },
      },
    ],
  },
];
export default business_manage_router_config;
