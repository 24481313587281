import Layout from "@/layout";

const driver_manage_router_config = [
  {
    path: "/driver-manage",
    component: Layout,
    hidden: true,
    children: [
      // {
      //   path: "/driver-manage/driver-list",
      //   component: () => import("@/views/driver-manage/list/index.vue"),
      //   hidden: true,
      //   meta: { title: "司机列表", icon: "look" },
      // },
    ],
  },
];

export default driver_manage_router_config;
