import Layout from "@/layout";

const operate_manage_router_config = [
  {
    path: "/operate-center",
    component: Layout,
    hidden: true,
    children: [
      // {
      //   path: "/operate-center/channel/list",
      //   component: () => import("@/views/operate-manage/channel/index.vue"),
      //   hidden: true,
      //   meta: { title: "单渠道利润率亏损限额", icon: "look" },
      // },
      // {
      //   path: "/operate-center/station/list",
      //   component: () => import("@/views/operate-manage/station/index.vue"),
      //   hidden: true,
      //   meta: { title: "单站单日累计亏损限额", icon: "look" },
      // },
      {
        path: "/operate-center/records/list",
        component: () => import("@/views/operate-manage/records/index.vue"),
        hidden: true,
        meta: { title: "超限记录", icon: "look" },
      },
      // {
      //   path: "/operate-center/channel-station/list",
      //   component: () =>
      //     import("@/views/operate-manage/channelStation/index.vue"),
      //   hidden: true,
      //   meta: { title: "单渠道单站利润率亏损限额", icon: "look" },
      // },
    ],
  },
];

export default operate_manage_router_config;
