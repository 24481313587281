const custom_small_router_config = [
  {
    path: "/small-sale/list",
    component: () => import("@/viewsmall/sale/list/index.vue"),
    hidden: true,
    meta: { title: "油站列表", icon: "look" },
  },
  {
    path: "/small-sale/statis-ls",
    component: () => import("@/viewsmall/sale/statis-ls/index.vue"),
    hidden: true,
    meta: { title: "油站流水统计", icon: "look" },
  },
  {
    path: "/small-sale/user",
    component: () => import("@/viewsmall/sale/user/index.vue"),
    hidden: true,
    meta: { title: "站联销售助手", icon: "look" },
  },
];

export default custom_small_router_config;
