import Layout from "@/layout";

const publish_manage_router_config = [
  {
    path: "/publish-manage",
    component: Layout,
    hidden: true,
    children: [
      {
        path: "/publish-manage/publish-look",
        component: () => import("@/views/publish-manage/lookList/index.vue"),
        hidden: true,
        meta: { title: "发布查看", icon: "look" },
      },
      {
        path: "/publish-manage/review-look",
        component: () => import("@/views/publish-manage/reviewLook/index.vue"),
        hidden: true,
        meta: { title: "审核查看", icon: "look" },
      },
    ],
  },
  {
    path: "/publish-manage",
    component: Layout,
    hidden: true,
    children: [
      {
        path: "/publish-manage/publish-cancel",
        component: () => import("@/views/publish-manage/create/index.vue"),
        hidden: true,
        meta: { title: "取消发布", icon: "look" },
      },
    ],
  },
  {
    path: "/publish-manage",
    component: Layout,
    hidden: true,
    children: [
      {
        path: "/publish-manage/publish-edit",
        component: () => import("@/views/publish-manage/create/index.vue"),
        hidden: true,
        meta: { title: "发布编辑", icon: "edit" },
      },
    ],
  },
  {
    path: "/publish-manage",
    component: Layout,
    hidden: true,
    children: [
      {
        path: "/publish-manage/publish-create",
        component: () => import("@/views/publish-manage/create/index.vue"),
        hidden: true,
        meta: { title: "发布创建", icon: "edit" },
      },
    ],
  },
  {
    path: "/publish-manage",
    component: Layout,
    hidden: true,
    children: [
      {
        path: "/publish-manage/publish-audit",
        component: () => import("@/views/publish-manage/audit/index.vue"),
        hidden: true,
        meta: { title: "发布审核", icon: "list" },
      },
      {
        path: "/publish-manage/publish-record",
        component: () => import("@/views/publish-manage/record/index.vue"),
        hidden: true,
        meta: { title: "变更记录", icon: "list" },
      },
    ],
  },
];

export default publish_manage_router_config;
