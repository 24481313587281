import gcoord from "gcoord";

// 高德经纬度转百度经纬度 经度 纬度
export function AutonaviTurnBaidu(lng, lat) {
  const result = gcoord.transform([lng, lat], gcoord.GCJ02, gcoord.BD09);
  return result;
  // return {
  //   lng: result[0],
  //   lat: result[1],
  // };
  // 高德 116.32715863448607,39.990912172420714
  // 百度 116.33377138639645,39.996645642589186
}

// 百度经纬度转高德经纬度 经度 纬度
export function BaiduTurnAutonavi(lng, lat) {
  const result = gcoord.transform([lng, lat], gcoord.BD09, gcoord.GCJ02);
  return result;
  // return {
  //   lng: result[0],
  //   lat: result[1],
  // };
  // 高德 116.32715863448607,39.990912172420714
  // 百度 116.33377138639645,39.996645642589186
}
