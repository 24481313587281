// 营销活动
import Layout from "@/layout";

const marketing_manage_router_config = [
  {
    path: "/marketing-manage",
    component: Layout,
    hidden: true,
    children: [
      // {
      //   path: "/marketing-manage/activity-list",
      //   component: () =>
      //     import("@/views/marketing-manage/activity-manage/list/index.vue"),
      //   hidden: true,
      //   meta: { title: "活动列表", icon: "list" },
      // },
      // 创建
      {
        path: "/marketing-manage/activity-create",
        component: () =>
          import("@/views/marketing-manage/activity-manage/create/index.vue"),
        hidden: true,
        meta: { title: "创建活动", icon: "list" },
      },
      {
        path: "/marketing-manage/activity-create5",
        component: () =>
          import("@/views/marketing-manage/activity-manage/create5/index.vue"),
        hidden: true,
        meta: { title: "创建活动-满送", icon: "list" },
      },
      // 发布管理
      // {
      //   path: "/marketing-manage/release-list",
      //   component: () =>
      //     import("@/views/marketing-manage/release-manage/list/index.vue"),
      //   hidden: true,
      //   meta: { title: "发布管理", icon: "list" },
      // },
      {
        path: "/marketing-manage/release-create",
        component: () =>
          import("@/views/marketing-manage/release-manage/create/index.vue"),
        hidden: true,
        meta: { title: "发布活动", icon: "list" },
      },
      {
        path: "/marketing-manage/release-platformCreate",
        component: () =>
          import(
            "@/views/marketing-manage/release-manage/platformCreate/index.vue"
          ),
        hidden: true,
        meta: { title: "发布活动-平台渠道", icon: "list" },
      },
      {
        path: "/marketing-manage/release-station",
        component: () =>
          import("@/views/marketing-manage/release-manage/station/index.vue"),
        hidden: true,
        meta: { title: "加油站营销价配置", icon: "list" },
      },
      {
        path: "/marketing-manage/release-config",
        component: () =>
          import("@/views/marketing-manage/release-manage/config/index.vue"),
        hidden: true,
        meta: { title: "活动叠加/互斥及优先级配置", icon: "list" },
      },
      {
        path: "/marketing-manage/release-look",
        component: () =>
          import("@/views/marketing-manage/release-manage/look/index.vue"),
        hidden: true,
        meta: { title: "查看活动", icon: "list" },
      },
      // 油站活动查询
      // {
      //   path: "/marketing-manage/station-list",
      //   component: () =>
      //     import("@/views/marketing-manage/station-manage/list/index.vue"),
      //   hidden: true,
      //   meta: { title: "油站活动查询", icon: "list" },
      // },
      // 到期预警
      // {
      //   path: "/marketing-manage/early-list",
      //   component: () =>
      //     import("@/views/marketing-manage/early-manage/list/index.vue"),
      //   hidden: true,
      //   meta: { title: "到期预警", icon: "list" },
      // },
      // 赠品管理
      {
        path: "/marketing-manage/station-record",
        component: () =>
          import("@/views/marketing-manage/station-manage/record/index.vue"),
        hidden: true,
        meta: { title: "油站活动变更记录", icon: "list" },
      },
      {
        path: "/marketing-manage/gift-list",
        component: () =>
          import("@/views/marketing-manage/gift-manage/list/index.vue"),
        hidden: true,
        meta: { title: "赠品管理", icon: "list" },
      },
      {
        path: "/marketing-manage/apply",
        component: () => import("@/views/marketing-manage/apply/index.vue"),
        hidden: true,
        meta: { title: "活动申请", icon: "list" },
      },
      {
        path: "/marketing-manage/records",
        component: () => import("@/views/marketing-manage/records/index.vue"),
        hidden: true,
        meta: { title: "操作记录", icon: "list" },
      },
      {
        path: "/marketing-manage/review",
        component: () => import("@/views/marketing-manage/review/index.vue"),
        hidden: true,
        meta: { title: "活动审核列表", icon: "list" },
      },
      {
        path: "/marketing-manage/audit",
        component: () => import("@/views/marketing-manage/audit/index.vue"),
        hidden: true,
        meta: { title: "活动审核", icon: "list" },
      },
    ],
  },
];
export default marketing_manage_router_config;
