import { ElMessage, ElMessageBox } from "element-plus";
import { oli_config } from "@/config/selectConfig";
import { utils, writeFile } from "xlsx";
import { mainBankList, bankBranckList } from "@/api/gas-station/public-api.js";

export { globalConvertPinyin } from "./pinyinUnicode";

export function globalGetdate(data = new Date()) {
  if (!data) {
    return "";
  }
  let date = new Date(data);
  if (date == "Invalid Date") {
    return "";
  }
  const y = date.getFullYear(); // 年代
  let m = date.getMonth() + 1; // 月份
  let d = date.getDate(); // 日
  let h = date.getHours(); // 时
  let mt = date.getMinutes(); //分
  let s = date.getSeconds(); //秒
  const sm = date.getMilliseconds(); //毫秒
  const ts = date.getTime(); //时间戳
  const wk = date.getDay(); //星期
  m = globalAddzero(m);
  d = globalAddzero(d);
  h = globalAddzero(h);
  mt = globalAddzero(mt);
  s = globalAddzero(s);
  date = `${y}-${m}-${d}`; //年月日
  const time = `${h}:${mt}:${s}`; //时分秒
  const long = `${date} ${time}`;
  const obj = {
    y,
    m,
    d,
    h,
    mt,
    s,
    sm,
    ts,
    wk,
    date,
    time,
    long,
  };
  // [年,月,日,时,分,秒,毫秒,时间戳,星期,年-月-日,时:分:秒]
  return obj;
}

function globalAddzero(n) {
  return String(n).padStart(2, "0");
}

// select输出文字
export function onSelectText(
  key,
  select_config,
  value = "value",
  label = "label"
) {
  if (!select_config) {
    return "";
  }
  const data = select_config.filter(
    (item) => String(item[value]) == String(key)
  );
  if (data.length === 1) {
    return data[0][label];
  }
  if (key === 0 || key === false) {
    return key;
  }
  return key || "";
}

// /components/FormGenerateCom/index.vue
// 组件初始类型操作
export function onFormGenerateFormat(config, type) {
  const list = config.map((item) => {
    item.head_config.type = type;
    return item;
  });
  return list;
}

// 请求返回处理 支持前端自定义提示
export function onRequestResult(res, fn) {
  if (res.code === 0 || res.code === 200) {
    ElMessage({
      type: "success",
      message: res.custom || res.msg || "操作成功!",
    });
    fn && fn();
  } else {
    ElMessage.error(res.msg);
  }
}
export function onRequestResult2(res, fn) {
  if (res.code === 0 || res.code === 200) {
    fn && fn();
  } else {
    ElMessage.error(res.msg);
  }
}

// select list重置，成为 value label 形式
export function onSelectFormat(list, label = "label", value = "value") {
  return list.map((item) => {
    item.label = item[label];
    item.value = item[value];
    return item;
  });
}

// 返回空值处理0保留，其余全部处理掉
export function onEmptyValueFormat(data) {
  for (let i in data) {
    if (data[i] === 0 || data[i] === false) {
      data[i] = data[i];
    } else {
      data[i] = data[i] || "-";
    }
  }
}

export function onObjectCreate(data) {
  return JSON.parse(JSON.stringify(data));
}

export function onBarreFormat(data) {
  for (let i in data) {
    if (data[i] === "-") {
      data[i] = "";
    }
  }
}

export function getOliNumberText(type, num) {
  try {
    return onSelectText(num, oli_config[String(type)].children);
  } catch (error) {
    return type;
  }
}

// input 输入格式限制
/*
type: 1纯数字 / 2数字+小数点3位 / 3英文+数字 / 4中文+英文+数字 / 5折扣0.1~9.9 / 6手机号 / 7车牌号
type: 8十六进制颜色值 / 9QQ / 10微信号 / 11邮箱 / 12URL / 13火车车次 / 14统一社会信用代码
type: 15在2号的基础上增加支持负数
*/
/*
@input="onInputFormat(scope.row, 'discount', 2)"
*/
export function onInputFormat(parent, key, type, other = {}) {
  let rtn = "";
  switch (type) {
    case 1:
      const text = String(parent[key]).replace(/\D*/g, "");
      // 最大值限制
      if (other.max) {
        rtn = Number(text) >= Number(other.max) ? other.max : text;
      } else {
        rtn = text;
      }
      break;
    case 3:
      rtn = parent[key].replace(/[\W,_]*/g, "");
      break;
    case 4:
      rtn = parent[key].replace(/[^A-Za-z0-9一-龟]*/g, "");
      break;
    case 5:
      rtn = numberDiscountReg(parent, key);
      break;
    case 6:
      rtn = numberPhoneReg(parent, key);
      break;
    case 7:
      rtn = licensePlateReg(parent, key);
      break;
    case 8:
      rtn = rgbHexReg(parent, key);
      break;
    case 9:
      rtn = qqReg(parent, key);
      break;
    case 10:
      rtn = weChatReg(parent, key);
      break;
    case 11:
      rtn = emailReg(parent, key);
      break;
    case 12:
      rtn = urlReg(parent, key);
      break;
    case 13:
      rtn = trainReg(parent, key);
      break;
    case 14:
      rtn = creditCodeReg(parent, key);
      break;
    case 15:
      rtn = parent[key].replace(/[^\d-]*/g, "");
      break;

    default:
      rtn = numberPointReg(parent, key, other);
      break;
  }
  parent[key] = rtn;
}

// 统一社会信用代码
// /^[0-9A-HJ-NPQRTUWXY]{2}\d{6}[0-9A-HJ-NPQRTUWXY]{10}$/
function creditCodeReg(parent, key) {
  let text = parent[key].replace(/[^0-9a-hj-npqrtuwxyA-HJ-NPQRTUWXY]/g, "");
  text = text.replace(/[a-z]/g, (match) => match.toLocaleUpperCase());
  const text1 = text.slice(0, 2);
  const text2 = text.slice(2, 8);
  const reg = /\d*/g;
  const is = reg.test(text2);
  text = is ? text : `${text1}`;
  text = text.slice(0, 18);
  return text;
}

// 火车车次
// /^[GCDZTSPKXLY1-9]\d{1,4}$/
function trainReg(parent, key) {
  let text = parent[key].replace(/[^gcdztspkxlyGCDZTSPKXLY1-9]/g, "");
  text = text.replace(/[a-z]/g, (match) => match.toLocaleUpperCase());
  const left = text.slice(0, 1);
  let right = text.slice(1);
  right = right.replace(/[^1-9]/g, "");
  text = `${left}${right}`;
  text = text.slice(0, 5);
  return text;
}

// URL
// /^(https?|ftp):\\/\\/[^\\s/$.?#].[^\\s]*$/
function urlReg(parent, key) {
  let text = parent[key].replace(/[\s$.?#]/g, "");
  const text1 = text.slice(0, 1);
  text = "hf".includes(text1) ? text : "";
  // 自动补全
  if (text == "h") {
    text = "http";
  }
  if (text == "http:") {
    text = "http://";
  }
  if (text == "https") {
    text = "https://";
  }
  if (text == "f") {
    text = "ftp://";
  }
  // //之后禁止输入/
  if (text.includes("://")) {
    const arr = text.split("://");
    const left = arr[0];
    let right = arr[1];
    right = right.replace(/[\s/$.?#]/g, "");
    text = `${left}://${right}`;
  }
  return text;
}

// 邮箱 由于.后面范围无法判断，故此无法做到完全拦截，无法确定最后.的位置因为可以拥有多个.
// /^[\w.%+-]+@[\w.-]+\.[a-zA-Z]{2,6}$/
function emailReg(parent, key) {
  let text = parent[key].replace(/[^\w.%+-@]/g, "");
  const is = text.includes("@");
  if (is) {
    // 首位不能为@
    if (text.slice(0, 1) == "@") {
      text = "";
    } else {
      const arr = text.split("@");
      const left = arr[0];
      let right = arr[1];
      text = `${left}@${right}`;
    }
  }
  return text;
}

// 微信号 6-20位
// /^[a-zA-Z][-_a-zA-Z0-9]{5,19}$/
function weChatReg(parent, key) {
  let text = parent[key].replace(/[^-_a-zA-Z0-9]/g, "");
  text = /[a-zA-Z]/.test(text.slice(0, 1)) ? text : "";
  text = text.slice(0, 20);
  return text;
}

// QQ 5-11位
// /^[1-9][0-9]{4,10}$/
function qqReg(parent, key) {
  let text = parent[key].replace(/[\D]/g, "");
  text = /[1-9]/.test(text.slice(0, 1)) ? text : "";
  text = text.slice(0, 11);
  return text;
}

// RGB Hex颜色值 rrggbb 不带前缀#
// /^#?([a-fA-F0-9]{6}|[a-fA-F0-9]{3})$/
function rgbHexReg(parent, key) {
  let text = parent[key].replace(/[^a-fA-F0-9]/g, "");
  text = text.slice(0, 6);
  return text;
}

// 车牌
// /^[京津沪渝冀豫云辽黑湘皖鲁新苏浙赣鄂桂甘晋蒙陕吉闽贵粤青藏川宁琼使领A-Z]{1}[A-Z]{1}[A-Z0-9]{4}[A-Z0-9挂学警港澳]{1}$/
function licensePlateReg(parent, key) {
  let text = parent[key].replace(
    /[^京津沪渝冀豫云辽黑湘皖鲁新苏浙赣鄂桂甘晋蒙陕吉闽贵粤青藏川宁琼使领A-Za-z0-9挂学警港澳]*/g,
    ""
  );
  const reg1 =
    /[京津沪渝冀豫云辽黑湘皖鲁新苏浙赣鄂桂甘晋蒙陕吉闽贵粤青藏川宁琼使领A-Z]/;
  const reg2 = /[A-Z]/;
  const reg3 = /[A-Z0-9]/g;
  const reg4 = /[A-Z0-9挂学警港澳]/;
  text = text.replace(/[a-z]/g, (match) => match.toLocaleUpperCase());
  const text1 = text.slice(0, 1);
  const text2 = text.slice(1, 2);
  const text3 = text.slice(2, 6);
  const text4 = text.slice(6, 7);
  text = reg4.test(text4) ? text : `${text1}${text2}${text3}`;
  text = reg3.test(text3) ? text : `${text1}${text2}`;
  text = reg2.test(text2) ? text : `${text1}`;
  text = reg1.test(text1) ? text : "";
  text = text.slice(0, 7);
  return text;
}

// 手机号
// /^1[3-9][0-9]{9}$/
function numberPhoneReg(parent, key) {
  let text = parent[key].replace(/[^\d]*/g, "");
  let len = text.length;
  if (len == 1) {
    text = 1;
  } else if (len == 2) {
    let two = text.slice(1, 2);
    const isTwo = /[3-9]/.test(two);
    if (isTwo) {
      text = `1${two}`;
    } else {
      text = 1;
    }
  } else {
    // 解决复制粘贴导致突破前两步
    const two = text.slice(1, 2);
    const isTwo = /[3-9]/.test(two);
    text = isTwo ? `1${two}${text.slice(2, 11)}` : `1`;
  }
  return text;
}

// 打折
function numberDiscountReg(parent, key) {
  let text = parent[key].replace(/[^\d,.。]*/g, "");
  text = text.replace(/[。]/g, ".");
  const isPoint = text.includes(".");
  if (isPoint && Number(text) < 10) {
    const arr = text.split(".");
    const left = arr[0].slice(0, 2);
    const right = arr[1].slice(0, 3);
    text = `${left}.${right}`;
  } else {
    text = text.slice(0, 2);
    text = Number(text) >= 10 ? 10 : text;
  }
  return text;
}

// 纯数字，带指定数量小数点
function numberPointReg(parent, key, other = {}) {
  let text = parent[key].replace(/[^\d-,.。]*/g, "");
  text = text.replace(/[。]/g, ".");
  const start = text.slice(0, 1);
  const end = text.slice(1).replace(/-/g, "");
  // 只允许开头有负号
  text = `${start}${end}`;
  const idx = text.indexOf(".");
  const lIdx = text.lastIndexOf(".");
  // 去除多余小数点 || 只能输入2位小数
  if (idx != lIdx || text.includes(".")) {
    const arr = text.split(".");
    const lastText = arr[1].replace(/\./g, "");
    text = `${arr[0]}.${lastText.slice(0, other.point || 3)}`;
  }
  // 最大值限制
  if (other.max || other.max === 0) {
    text = Number(text) >= Number(other.max) ? other.max : text;
  }
  return text;
}

export function onXlsxDownload(titleList, tableList, exportName) {
  const rows = tableList;

  /* generate worksheet and workbook */
  const worksheet = utils.json_to_sheet(rows);
  const workbook = utils.book_new();
  utils.book_append_sheet(workbook, worksheet, "Dates");

  /* fix headers */
  utils.sheet_add_aoa(worksheet, [titleList], { origin: "A1" });

  /* calculate column width */
  const wchs = [];
  titleList.forEach((item) => {
    wchs.push({
      wch: 20,
    });
  });
  worksheet["!cols"] = wchs;

  /* create an XLSX file and try to save to Presidents.xlsx */
  writeFile(workbook, `${exportName}-${Date.now()}.xlsx`, {
    compression: true,
  });
}

// 参数分析
export function globalQueryJson(searchText = location.search) {
  if (searchText) {
    const queryText = searchText.slice(1);
    const queryArr = queryText.split("&");
    const query = {};
    queryArr.forEach((item) => {
      const arr = item.split("=");
      query[arr[0]] = arr[1];
    });
    return query;
  }
  return {};
}
// 参数合成
export function globalQueryText(url = location.href, data = {}) {
  const baseArr = Object.entries(data);
  const len = baseArr.length;
  if (len <= 0) {
    return url;
  }
  const prefix = location.href.split("?")[0];
  let search = "";
  for (const [key, value] of baseArr) {
    search += `&${key}=${value}`;
  }
  search = search.slice(1);
  const rtn = `${url}?${search}`;
  return rtn;
}

export function isPhone() {
  const WIN = window;
  const NA = WIN.navigator;
  const UA = NA.userAgent.toLowerCase();
  function test(needle) {
    return needle.test(UA);
  }
  const IsAndroid = test(/android|htc/) || /linux/i.test(NA.platform + "");
  const IsIPad = !IsAndroid && test(/ipad/);
  const IsIPhone = !IsAndroid && test(/ipod|iphone/);
  const IsIOS = IsIPad || IsIPhone;
  const IsWinPhone = test(/windows phone/);
  const IsPC = !IsAndroid && !IsIOS && !IsWinPhone;
  return !IsPC;
}

// 获取总行列表
export function globalRequestMainBank(fn) {
  // 这里采用异步获取，数据量过大，会导致页面卡顿
  mainBankList().then((res) => {
    const bank_list = onSelectFormat(res.data, "bankName", "bankName");
    const other = {
      label: "其他",
      value: "其他",
    };
    const bankList = [...bank_list, other];
    fn && fn(bankList);
  });
}

// 获取支行列表，返回label
export function globalRequestBankBranck(id, fn) {
  // 这里采用异步获取，数据量过大，会导致页面卡顿
  bankBranckList(id).then((res) => {
    const bank_list = onSelectFormat(
      res.data,
      "branchBankName",
      "branchBankName"
      // "branchBankNum"
    );
    const other = {
      label: "其他",
      value: "其他",
    };
    const bankList = [...bank_list, other];
    fn && fn(bankList);
  });
}
