<template>
  <div>
    <div class="map_location">
      <div class="map_location_left">
        <div class="map_location_city">
          <el-cascader
            placeholder="请选择城市"
            :options="options"
            v-model="state.citys"
            popper-class="map_location_popper"
            :props="mapProps"
            :filterable="true"
          />
        </div>
        <el-input v-model.trim="state.addr" placeholder="请输入关键词，越多越精准" />
        <el-button type="primary" @click="onSearch" round>搜索</el-button>
      </div>
      <div class="map_location_right">
        <div class="map_location_text">{{ state.lng }}, {{ state.lat }}</div>
        <slot>
          <el-button type="primary" round>确定经纬度</el-button>
          <el-button type="danger" round>关闭</el-button>
        </slot>
      </div>
    </div>
    <!-- @tilesloaded="onTilesloaded" -->
    <BMap
      class="map_box"
      width="100%"
      height="100%"
      :center="point"
      :zoom="state.zoom"
      :enableScrollWheelZoom="true"
      @click="onClick"
      @initd="handleInitd"
      ref="map"
      @mousemove="onMousemove"
    >
      <BZoom />
      <BMarker :position="point || { lng: 0, lat: 0 }"></BMarker>
      <BCityList v-if="props.isMap" />
      <BLocation />
      <BLabel
        :content="state.text || '0'"
        :position="{ lng: state.x, lat: state.y }"
        :style="style"
      />
    </BMap>
  </div>
</template>

<script setup>
import { ref, reactive, toRefs } from "vue";
import {
  BMap,
  BZoom,
  BMarker,
  BCityList,
  BLocation,
  BLabel,
  useAddressGeocoder,
  usePointGeocoder,
} from "vue3-baidu-map-gl";
import { codeToText, regionData } from "element-china-area-data";

const options = ref(regionData);
const props = defineProps({
  isMap: Boolean,
});

const mapProps = {
  checkStrictly: true,
};

const state = reactive({
  lng: 0,
  lat: 0,
  addr: "上地",
  // addr: "河南加油站",
  city: "北京市",
  citys: ["11"],
  zoom: 14,
  x: 0,
  y: 0,
  text: "",
  address: "", // 详细地址
  adcode: "", // 区域码
  surroundingPois: [], // 区域商家信息
});
const map = ref();

const { get, point } = useAddressGeocoder(() => {
  map.value.resetCenter();
});

const { result, get: getInfo, isLoading, isEmpty } = usePointGeocoder();

const style = {
  color: "#000",
  border: "solid 1px #000",
  backgroundColor: "#fff",
  borderRadius: "3px",
  lineHeight: "1.2",
  fontSize: "12px",
  margin: "8px",
};

let timer = null;
function onClick(e) {
  const { latlng } = e;
  const { lng, lat } = latlng;
  state.lng = lng;
  state.lat = lat;
  getInfo(e.latlng);
  // 解决信息获取缓慢问题，导致第二次才能获取第一次的结果
  clearTimeout(timer);
  timer = setTimeout(() => {
    if (result["_value"]) {
      // console.log(result['_value'], 999)
      const {
        address,
        content: {
          address_detail: { adcode },
        },
        surroundingPois,
      } = result["_value"];
      state.address = address;
      state.adcode = adcode;
      state.surroundingPois = surroundingPois;
    }
  }, 300);
}

function onSearch() {
  const code = state.citys.slice(-1);
  // console.log(state.addr, state.addr, 99999)
  state.city = codeToText[code];
  get(state.addr, state.city);
  state.zoom = 19;
  // try {
  //     get(state.addr, state.addr)
  // } catch (error) {
  //     console.log('error=>', error)
  //     get(state.addr, state.city)
  // }
}

function handleInitd() {
  get(state.addr, state.city);
}

function onTilesloaded(e) {
  const city = e.currentTarget.cityName;
  if (city) {
    state.city = city;
  }
}

function onMousemove(e) {
  const { latlng } = e;
  const { lng, lat } = latlng;
  state.text = `${lng}, ${lat}`;
  state.x = lng;
  state.y = lat;
}

defineExpose({
  ...toRefs(state),
});
</script>
<style scoped lang="scss">
@import url("./style.scss");
</style>
