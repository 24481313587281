import Layout from "@/layout";

const service_manage_router_config = [
  {
    path: "/service-manage",
    component: Layout,
    hidden: true,
    children: [
      {
        path: "/service-manage/service-look",
        component: () => import("@/views/service-manage/create/index.vue"),
        hidden: true,
        meta: {
          title: "服务商查看",
          icon: "look",
        },
      },
    ],
  },
  {
    path: "/service-manage",
    component: Layout,
    hidden: true,
    children: [
      {
        path: "/service-manage/service-create-edit",
        component: () => import("@/views/service-manage/create/index.vue"),
        hidden: true,
        meta: { title: "服务商创建编辑", icon: "look" },
      },
    ],
  },
  {
    path: "/service-manage",
    component: Layout,
    hidden: true,
    children: [
      {
        path: "/service-manage/service-edit",
        component: () => import("@/views/service-manage/create/index.vue"),
        hidden: true,
        meta: { title: "服务商编辑", icon: "edit" },
      },
    ],
  },
  {
    path: "/my-review",
    component: Layout,
    hidden: true,
    children: [
      {
        path: "/my-review/reviewing-service",
        component: () =>
          import("@/views/service-manage/review/reviewing/index.vue"),
        hidden: true,
        meta: { title: "待审核列表", icon: "list" },
      },
    ],
  },
  {
    path: "/my-review",
    component: Layout,
    hidden: true,
    children: [
      {
        path: "/my-review/reviewed-service",
        component: () =>
          import("@/views/service-manage/review/reviewed/index.vue"),
        hidden: true,
        meta: { title: "历史审核列表", icon: "list" },
      },
    ],
  },
  {
    path: "/my-review",
    component: Layout,
    hidden: true,
    children: [
      {
        path: "/my-review/audit",
        component: () => import("@/views/service-manage/audit/index.vue"),
        hidden: true,
        meta: { title: "服务商审核", icon: "list" },
      },
    ],
  },
];

export default service_manage_router_config;
