export const keep_alive_exclude = [
  "Entry",
  "User",
  "Gas-station-create",
  "Channel-create",
  "Service-create",
  "Publish-before",
  "Publish-after",
  "Create",
];

// request timeout config
export const request_timeout_config = [
  "/gas-station/list", // 加油站列表
  "/report-forms/station-zk", // 报表中心-油站折扣
  "/oil-gun/change-list", // 油枪价管理-油枪价变更记录
  "/publish-manage/publish-list", // 发布列表
  "/account-manage/station-account/front-all/list", // 财务中心-充值总账户
  "/account-manage/station-account/front/list", // 财务中心-充值子账户
  "/gas-station-ad/gas-station-ad-list", // 充值返现优惠
  "/nation-manage/nation-link", // 国标价管理-油站调价链接
  "/nation-manage/nation-link-records", // 国标价管理-未调价明细
  "/order-manage/order-list", // 订单下载
  "/report-forms/station-jl", // 报表中心-油站净利
];
