<template>
  <div class="page_box">
    <el-dialog v-model="state.isDialog" title="批量导入" width="90%">
      <div class="export_top">
        <div class="export_top_box">
          <el-card shadow="never">
            <template #header>批量导入油站</template>
            <el-form-item label="油站：" label-width="90">
              <el-upload
                ref="uploadRef"
                action="#"
                :auto-upload="false"
                :multiple="false"
                :show-file-list="false"
                :limit="1"
                :on-exceed="handleExceed"
                v-model:file-list="state.uploadTitle"
                :on-change="handleChange"
                accept=".xls, .xlsx"
                id="upload_file"
              >
                <template #trigger>
                  <el-button type="primary">上传文件</el-button>
                </template>
              </el-upload>
              <el-button
                type="primary"
                link
                @click="getDownTemplate"
                class="download_btn"
                >下载模版</el-button
              >
            </el-form-item>
            <el-form-item label="" label-width="90">{{
              state.filename
            }}</el-form-item>
          </el-card>
          <el-card shadow="never">
            <template #header>批量导入油站结果</template>
            <div>
              导入{{ state.data.totalCount }}条，成功{{
                state.data.successCount
              }}条，失败{{ state.data.errorCount }}条
            </div>
            <el-table :data="state.data.errorDetailRespVoList" stripe>
              <el-table-column prop="gasId" label="加油站编号" />
              <el-table-column prop="stationName" label="加油站名称" />
              <el-table-column prop="result" label="导入结果" />
            </el-table>
          </el-card>
        </div>
      </div>
      <template #footer>
        <el-button @click="state.isDialog = false">取消</el-button>
        <el-button type="primary" @click="onSave">确定</el-button>
      </template>
    </el-dialog>
  </div>
</template>

<script setup lang="ts">
import { onMounted, ref, reactive, watch, computed } from "vue";
import { ElMessage, ElMessageBox } from "element-plus";
import { codeToText, regionData } from "element-china-area-data";
// import BigNumber from "bignumber.js";
import {
  globalGetdate,
  onSelectText,
  onFormGenerateFormat,
  onRequestResult,
  onSelectFormat,
  onEmptyValueFormat,
  onObjectCreate,
  onBarreFormat,
  getOliNumberText,
  onInputFormat,
  onXlsxDownload,
} from "@/utils/date_tools";
import { useRouter, useRoute } from "vue-router";
import useUserStore from "@/store/modules/user";
// import { User } from "@element-plus/icons-vue";
// import { } from "@/config/selectConfig";
import {
  downTemplate,
  managementImport,
} from "@/api/marketing-manage/activity-manage.js";
import { genFileId } from "element-plus";

const options = ref(regionData); // 地理位置选项数据源
const emits = defineEmits(["success"]);
const props: any = defineProps({
  selectStationData: Object,
});

const router = useRouter();
const route = useRoute();
const detail_id = route.query.id; // query id
const uploadRef: any = ref();

const state = reactive({
  input: "",
  isDialog: false,
  filename: "",
  data: {},
}) as any;

onMounted(async () => {
  init();
});

// 清空上传文件
const handleExceed = (files) => {
  uploadRef.value.clearFiles();
  const file = files[0];
  file.uid = genFileId();
  uploadRef.value.handleStart(file);
};

// 上传文件改变时
const handleChange = async (file) => {
  const { channelId, oilNo } = props.selectStationData;
  const types = ["xls", "xlsx"];
  const suffix = file.name.split(".")[1];
  const is = types.includes(suffix);
  if (!is) {
    ElMessage.error(`请上传正确格式!`);
    return false;
  }
  state.filename = file.name;
  let formData = new FormData();
  formData.append("file", file.raw);
  formData.append("typeId", state.val);
  formData.append("channelId", channelId);
  formData.append("oilNo", oilNo);
  const res = await managementImport(formData);
  state.data = res.data;
};

async function init() {}

function onQuery() {}

function onReset() {
  state.input = "";
}

function getDownTemplate() {
  const url = downTemplate();
  window.open(url);
}

function open() {
  state.isDialog = true;
}

function onSave() {
  if (state.data.gasIdList.length <= 0) {
    ElMessage.error("没有成功油站，请确认!");
    return false;
  }
  state.isDialog = false;
  emits("success", state.data);
}

// const computed_sample = computed(() => {
//   return "end";
// });

// watch(
//   () => route.fullPath,
//   (newValue) => {},
//   {
//     immediate: true,
//     deep: true,
//   }
// );

defineExpose({
  open,
});
</script>

<style scoped lang="scss">
@import url(./style.scss);
</style>
