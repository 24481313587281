import PictureUploadCom from "@/components/PictureUploadCom/index.vue";
import ChooseLatLonCom from "@/components/ChooseLatLonCom/index.vue";
import PictureUploadComOnly from "@/components/PictureUploadComOnly/index.vue";
import PictureUploadComMultiple from "@/components/PictureUploadComMultiple/index.vue";
import FormGenerateCom from "@/components/FormGenerateCom/index.vue";
import TableCom from "@/components/TableCom/index.vue";
import ContractInfoMore from "@/components/ContractInfoMore/index.vue";
import TableMerge from "@/components/TableMerge/index.vue";
import TableMergeApply from "@/components/TableMergeApply/index.vue";
import AmapLatLonCom from "@/components/AmapLatLonCom/index.vue";
import ContractInfoMoreLook from "@/components/ContractInfoMoreLook/index.vue";
import ToolTipGlobalCom from "@/components/ToolTipCom/index.vue";
import TableHeaderCustomCon from "@/components/TableHeaderCustomCon/index.vue";
import BatchUploadGasStations from "@/components/BatchUploadGasStations/index.vue";

const global_com_list = [
  {
    name: "BatchUploadGasStations",
    com: BatchUploadGasStations,
  },
  {
    name: "TableHeaderCustomCon",
    com: TableHeaderCustomCon,
  },
  {
    name: "ToolTipGlobalCom",
    com: ToolTipGlobalCom,
  },
  {
    name: "ContractInfoMoreLook",
    com: ContractInfoMoreLook,
  },
  {
    name: "AmapLatLonCom",
    com: AmapLatLonCom,
  },
  {
    name: "TableMergeApply",
    com: TableMergeApply,
  },
  {
    name: "TableMerge",
    com: TableMerge,
  },
  {
    name: "PictureUploadCom",
    com: PictureUploadCom,
  },
  {
    name: "ChooseLatLonCom",
    com: ChooseLatLonCom,
  },
  {
    name: "PictureUploadComOnly",
    com: PictureUploadComOnly,
  },
  {
    name: "PictureUploadComMultiple",
    com: PictureUploadComMultiple,
  },
  {
    name: "FormGenerateCom",
    com: FormGenerateCom,
  },
  {
    name: "TableCom",
    com: TableCom,
  },
  {
    name: "ContractInfoMore",
    com: ContractInfoMore,
  },
];

function globalComInit(app) {
  global_com_list.forEach((item) => {
    app.component(item.name, item.com);
  });
}

export default globalComInit;
