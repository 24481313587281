import Layout from "@/layout";

const operation_record_config = [
  {
    path: "/operation-record",
    component: Layout,
    hidden: true,
    children: [
      {
        path: "/operation-record/list",
        component: () => import("@/views/operation-record/index.vue"),
        hidden: true,
        meta: { title: "操作记录", icon: "list" },
      },
    ],
  },
];

export default operation_record_config;
