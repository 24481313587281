export const cityListConfig = [
    { "value": "11", "label": "北京市", "center": [116.407387, 39.904179] },
    { "value": "12", "label": "天津市", "center": [117.201509, 39.085318] },
    { "value": "13", "label": "河北省", "center": [114.530399, 38.037707] },
    { "value": "14", "label": "山西省", "center": [112.578781, 37.813948] },
    { "value": "15", "label": "内蒙古自治区", "center": [111.765226, 40.818233] },
    { "value": "21", "label": "辽宁省", "center": [123.435093, 41.836743] },
    { "value": "22", "label": "吉林省", "center": [125.325802, 43.896942] },
    { "value": "23", "label": "黑龙江省", "center": [126.661998, 45.742253] },
    { "value": "31", "label": "上海市", "center": [121.473667, 31.230525] },
    { "value": "32", "label": "江苏省", "center": [118.763563, 32.061377] },
    { "value": "33", "label": "浙江省", "center": [120.152575, 30.266619] },
    { "value": "34", "label": "安徽省", "center": [117.330139, 31.734559] },
    { "value": "35", "label": "福建省", "center": [119.296194, 26.101082] },
    { "value": "36", "label": "江西省", "center": [115.816587, 28.637234] },
    { "value": "37", "label": "山东省", "center": [117.020725, 36.670201] },
    { "value": "41", "label": "河南省", "center": [113.753094, 34.767052] },
    { "value": "42", "label": "湖北省", "center": [114.341552, 30.546222] },
    { "value": "43", "label": "湖南省", "center": [112.982951, 28.116007] },
    { "value": "44", "label": "广东省", "center": [113.266887, 23.133306] },
    { "value": "45", "label": "广西壮族自治区", "center": [108.327964, 22.802248] },
    { "value": "46", "label": "海南省", "center": [110.348781, 20.018639] },
    { "value": "50", "label": "重庆市", "center": [106.550483, 29.563707] },
    { "value": "51", "label": "四川省", "center": [104.076452, 30.651696] },
    { "value": "52", "label": "贵州省", "center": [106.705251, 26.600328] },
    { "value": "53", "label": "云南省", "center": [102.709372, 25.046432] },
    { "value": "54", "label": "西藏自治区", "center": [91.117449, 29.648694] },
    { "value": "61", "label": "陕西省", "center": [108.953939, 34.266611] },
    { "value": "62", "label": "甘肃省", "center": [103.826777, 36.060634] },
    { "value": "63", "label": "青海省", "center": [101.780482, 36.622538] },
    { "value": "64", "label": "宁夏回族自治区", "center": [106.258889, 38.472273] },
    { "value": "65", "label": "新疆维吾尔自治区", "center": [116.333112, 39.933248] }
];