<template>
  <div class="picture_box">
    <slot :imageUrl="imageUrl" :file_key="file_key">
      <!-- <div class="upload_img active" :style="`background-image:url(${imageUrl});`" v-show="imageUrl"></div> -->
      <!-- <div class="upload_img" v-show="!imageUrl"></div> -->
      <div class="upload_input">
        <div class="upload_input_text" @click="onLook(imageUrl)">
          {{ file_key }}
        </div>
        <el-upload
          accept="image/jpeg,image/gif,image/png"
          name="images"
          :action="`${apiBaseUrl}/fileOss/upload`"
          :show-file-list="false"
          :on-success="handleAvatarSuccess"
          :before-upload="beforeAvatarUpload"
          :on-error="handleAvatarError"
          :headers="headers"
        >
          <div class="upload_input_box">
            <div class="upload_input_btn">
              <slot name="title"></slot>
            </div>
          </div>
        </el-upload>
      </div>
    </slot>
    <div class="look_img" :class="{ active: isLook }">
      <div class="look_img_bg"></div>
      <div class="look_img_loading"><LoadingCom /></div>
      <div
        class="look_img_box"
        :style="`background-image:url(${look_url});`"
      ></div>
      <div class="look_img_shut" @click="isLook = false"></div>
    </div>
  </div>
</template>
<script setup>
import { ref, watch } from "vue";
import { ElMessage, ElLoading } from "element-plus";
const apiBaseUrl = import.meta.env.VITE_APP_BASE_API;
import LoadingCom from "@/components/LoadingCom/index";
import { getToken } from "@/utils/auth";

const image_domain = import.meta.env.VITE_IMG_UPLOAD_API;

const headers = ref({
  Authorization: "Bearer " + getToken(),
});

const props = defineProps({
  img_url: String,
  img_key: String,
});

const isLook = ref(false);
const look_url = ref("");
const imageUrl = ref("");
const file_key = ref("");
let loading = null;

const handleAvatarSuccess = (response, uploadFile) => {
  const { fileKey, fileurl } = response.data;
  file_key.value = fileKey;
  imageUrl.value = `${image_domain}/${fileKey}`;
  loading.close();
};

const beforeAvatarUpload = (rawFile) => {
  loading = ElLoading.service({
    lock: true,
    text: "上传中...",
    background: "rgba(0, 0, 0, 0.7)",
  });

  // if (rawFile.type !== "image/jpeg") {
  //   ElMessage.error("Avatar picture must be JPG format!");
  //   return false;
  // }
  // if (rawFile.type !== 'image/jpeg') {
  //     ElMessage.error('Avatar picture must be JPG format!')
  //     return false
  // } else if (rawFile.size / 1024 / 1024 > 2) {
  //     ElMessage.error('Avatar picture size can not exceed 2MB!')
  //     return false
  // }
  return true;
};

const handleAvatarError = (error) => {
  const data = JSON.parse(error["message"]);
  ElMessage.error(`上传失败：${data.message}`);
  loading.close();
};

function onLook(url) {
  if (url) {
    let url2 = url;
    if (url.includes("?")) {
      url2 = url.split("?")[0];
    }
    look_url.value = url2;
    isLook.value = true;
  }
}

watch(
  () => props,
  (newValue) => {
    if (newValue.img_key) {
      imageUrl.value = `${image_domain}/${newValue.img_key}`;
      file_key.value = newValue.img_key;
    } else {
      imageUrl.value = "";
      file_key.value = "";
    }
  },
  {
    immediate: true,
    deep: true,
  }
);

defineExpose({
  file_key,
  imageUrl,
});
</script>

<style scoped lang="scss">
@import url(./style.scss);
</style>
