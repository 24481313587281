<template>
  <div style="height: 48px; display: flex; align-items: center">
    <div class="bread_box">
      <div class="bread_back" @click="onBack" v-if="route.path != '/index'">
        <svg-icon icon-class="back" />
      </div>
      <div class="bread_title">
        {{ route.meta.title }}
      </div>
    </div>
    <!-- <el-breadcrumb class="app-breadcrumb" separator="/">
      <transition-group name="breadcrumb">
        <el-breadcrumb-item v-for="(item, index) in levelList" :key="item.path">
          <span
            v-if="
              item.redirect === 'noRedirect' || index == levelList.length - 1
            "
            class="no-redirect"
            >{{ item.meta.title }}</span
          >
          <a v-else @click.prevent="handleLink(item)">{{ item.meta.title }}</a>
        </el-breadcrumb-item>
      </transition-group>
    </el-breadcrumb> -->
  </div>
</template>

<script setup>
const route = useRoute();
const router = useRouter();
const levelList = ref([]);

function getBreadcrumb() {
  // only show routes with meta.title
  let matched = route.matched.filter((item) => item.meta && item.meta.title);
  const first = matched[0];
  // 判断是否为首页
  if (!isDashboard(first)) {
    // { path: '/index', meta: { title: '首页' } }
    matched = [].concat(matched);
  }

  levelList.value = matched.filter(
    (item) => item.meta && item.meta.title && item.meta.breadcrumb !== false
  );
}
function isDashboard(route) {
  const name = route && route.name;
  if (!name) {
    return false;
  }
  return name.trim() === "Index";
}
function handleLink(item) {
  console.log(item, 7777);
  const { redirect, path } = item;
  if (redirect) {
    router.push(redirect);
    return;
  }
  router.push(path);
}

function onBack() {
  const idx = router.back();
  if (history.length <= 1) {
    window.close();
  }
}

watchEffect(() => {
  // if you go to the redirect page, do not update the breadcrumbs
  if (route.path.startsWith("/redirect/")) {
    return;
  }
  getBreadcrumb();
});
getBreadcrumb();
</script>

<style lang="scss" scoped>
.app-breadcrumb.el-breadcrumb {
  display: inline-block;
  font-size: 14px;
  line-height: 50px;
  margin-left: 8px;

  .no-redirect {
    color: #97a8be;
    cursor: text;
  }
}

.bread_box {
  align-items: center;
  display: flex;
  .bread_back {
    cursor: pointer;
    margin-right: 8px;

    &:hover {
      transition: all 0.3s;
      transform: translateX(-2px);
    }
  }
  .bread_title {
  }
}
</style>
