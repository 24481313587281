import Layout from "@/layout";

const order_manage_router_config = [
  // {
  //   path: "/order-manage",
  //   component: Layout,
  //   hidden: true,
  //   children: [
  //     {
  //       path: "/order-manage/order-list",
  //       component: () => import("@/views/order-manage/list/index.vue"),
  //       hidden: true,
  //       meta: { title: "订单列表", icon: "list" },
  //     },
  //   ],
  // },
  {
    path: "/order-manage",
    component: Layout,
    hidden: true,
    children: [
      {
        path: "/order-manage/order-look",
        component: () => import("@/views/order-manage/create/index.vue"),
        hidden: true,
        meta: {
          title: "订单详情",
          icon: "create",
          activeMenu: "/order-manage/order-list",
        },
      },
      {
        path: "/order-manage/apply-refund",
        component: () => import("@/views/order-manage/apply-refund/index.vue"),
        hidden: true,
        meta: {
          title: "申请退款",
          icon: "list",
          activeMenu: "/order-manage/order-list",
        },
      },
      // {
      //   path: "/order-manage/refund-audit",
      //   component: () => import("@/views/order-manage/refund-audit/index.vue"),
      //   hidden: true,
      //   meta: {
      //     title: "退款审核",
      //     icon: "list",
      //   },
      // },
      {
        path: "/order-manage/refund-audit-detail",
        component: () =>
          import("@/views/order-manage/refund-audit-detail/index.vue"),
        hidden: true,
        meta: {
          title: "详情",
          icon: "list",
        },
      },
    ],
  },
];
export default order_manage_router_config;
